import R from 'ramda'
import { createSelector } from 'reselect'

import { generateBaskets, getTrashTagsVisibleOnArticle, getVisibleTrashTags } from '../opoint/tags/trash'

import { getAlertTags, getAllAlertTags } from './alertsSelectors'
import { getAnalyticsTags } from './analytics'
import { getArticleTrashTags } from './articlesSelectors'
import { getSelectedProfilesIds, getTrashTagFilterIds } from './searchSelectors'
import { getUISetting } from './settingsSelectors'
import { getLastUsedTagId, getTags } from './tagsSelectors'
import { getAllTrashTags } from './trashTagsSelectors'

export const getTagById = (tagId) =>
  createSelector(getTags, getAllAlertTags, getAnalyticsTags, (tags, alertTags, analyticsTags) =>
    [...tags, ...alertTags, ...analyticsTags].find(({ id }) => tagId === id),
  )

export const getLastUsedTag = createSelector(R.identity, getLastUsedTagId, (state, lastUsedTagId) =>
  getTagById(lastUsedTagId)(state),
)

// get trash tags displayed in entity listing
export const getVisibleTrashTagsInListing = createSelector(
  getAllTrashTags,
  getSelectedProfilesIds,
  getTrashTagFilterIds,
  (trashTags, profiles, trashTagFilterIds) => getVisibleTrashTags(trashTags, profiles, trashTagFilterIds),
)

// get trash tags which are displayed in article footer
export const getActiveTrashTags = createSelector(
  getAllTrashTags,
  getVisibleTrashTagsInListing,
  getUISetting('TRASH_TAGS_VISIBLE'),
  getTrashTagFilterIds,
  getArticleTrashTags,
  (trashState, trashInListing, active, trashTagFilterIds, articleTrash) => (article) =>
    getTrashTagsVisibleOnArticle(trashState, trashInListing, active, trashTagFilterIds, articleTrash(article)),
)

export const getBaskets = createSelector(getTags, getAllTrashTags, getAllAlertTags, (tags, trashTags, alertTags) =>
  generateBaskets(tags, trashTags, alertTags),
)

export const getAllTagLikeEntities = createSelector(
  getTags,
  getActiveTrashTags,
  getAlertTags,
  (tags, trashTags, alertTags) => (article) => [...tags, ...trashTags(article), ...alertTags],
)
