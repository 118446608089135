import dayjs from 'dayjs'

import { capitalizeFirstLetter } from '../../../helpers/common'
import { getSearchObj } from '../../../helpers/location'
import { Folder, MediaType } from '../../../opoint/flow'

const BROADCAST_ICON_IDS = [577, 578]

export const getMediaTypeForIcon = ({
  topics = [],
  mediatype,
}: {
  topics: Array<{
    id: number
    text: string
    linktype_max: number
    linktype_now: number
    metacat: number
    metacat_level: number
  }>
  mediatype: MediaType
}): MediaType => {
  const topicIds = topics?.map(({ id }) => id)
  const isBroadcast = topicIds?.filter((id) => BROADCAST_ICON_IDS?.includes(id)).length !== 0

  const formattedMediaType = isBroadcast && mediatype === 'WEB' ? 'RADIO' : mediatype

  return formattedMediaType
}

export const getMilliseconds: () => {
  years: (time) => number
  months: (time) => number
  days: (time) => number
  hours: (time) => number
} = () => {
  const monthsInYear = 12
  const monthInDays = 30.44
  const dayInHours = 24
  const hourInMinutes = 60
  const minuteInSeconds = 60
  const secondInMiliseconds = 1000

  const years = (time) => {
    const yearsInMiliseconds =
      time * monthsInYear * monthInDays * dayInHours * hourInMinutes * minuteInSeconds * secondInMiliseconds

    return yearsInMiliseconds
  }
  const months = (time) => {
    const monthsInMiliseconds = time * monthInDays * dayInHours * hourInMinutes * minuteInSeconds * secondInMiliseconds

    return monthsInMiliseconds
  }
  const days = (time) => {
    const daysInMiliseconds = time * dayInHours * hourInMinutes * minuteInSeconds * secondInMiliseconds

    return daysInMiliseconds
  }
  const hours = (time) => {
    const hoursInMiliseconds = time * hourInMinutes * minuteInSeconds * secondInMiliseconds

    return hoursInMiliseconds
  }

  return { years, months, days, hours }
}

export const getContextFolder: (contentId: number, folders: Array<Folder>) => Folder = (contentId, folders) => {
  const folder = folders.find((folder) => folder.id === contentId)

  return folder
}

export const getCompanySentiment: (
  auto: string,
  manual: string,
  tempCompanyOverriddenSentiment: string,
  isTemporarilyOverridden: boolean,
  isCompanySentimentOverridden: boolean,
) => string = (auto, manual, tempCompanyOverriddenSentiment, isTemporarilyOverridden, isCompanySentimentOverridden) => {
  if (auto) {
    if (isTemporarilyOverridden) {
      return tempCompanyOverriddenSentiment
    } else {
      return capitalizeFirstLetter(isCompanySentimentOverridden ? manual : auto)
    }
  } else {
    if (isTemporarilyOverridden && !auto) {
      return tempCompanyOverriddenSentiment
    } else {
      return 'Not set'
    }
  }
}

export const containsAnyLetter: (str: string) => boolean = (str) => /[a-z]/i.test(str)

export const handleSelectedBaskets: (baskets: string, savedBaskets: string) => string | undefined = (
  baskets,
  savedBaskets,
) => {
  if (!!savedBaskets) {
    return savedBaskets
  }

  if (!!baskets) {
    return baskets
  }

  return undefined
}

export const getFiltersStringArray: () => Array<string> = () => {
  const searchObject = getSearchObj(window.location.search)
  const filtersStrings = searchObject?.filters?.split(';') ?? []

  return filtersStrings
}

export const formatRangeValue: (value: number) => string = (value) => {
  const formattedValue = dayjs(value ?? NaN, 'x') // If rangeStart is null, pass NaN instead, to prevent dayjs from throwing an error.

  return formattedValue?.isValid() && formattedValue.toISOString()
}
