import * as d3 from 'd3'
import { Dayjs } from 'dayjs'
import * as dc from 'dc'
import { saveAs } from 'file-saver'
import moment from 'moment'
import __ from 'ramda/src/__'
import flatten from 'ramda/src/flatten'
import keys from 'ramda/src/keys'
import partialRight from 'ramda/src/partialRight'
import reduce from 'ramda/src/reduce'
import uniq from 'ramda/src/uniq'
import values from 'ramda/src/values'

import * as ActionTypes from '../../../constants/actionTypes'
import buildAction from '../../../helpers/buildAction'
import { Dimension } from '../../../new-components/types/statistics'
import OpointDate from '../../../opoint/common/time'
import store from '../../../store'

const SERIES_KEY_I = 0
const DIMENSION_KEY_I = 1

export const NARROW_CHART_WIDTH = 295
export const WIDE_CHART_WIDTH = 570

export type KeyAccessorType = (d: any) => string | Date
export type KeyRounderType = (round: any) => any
export type SeriesAccessorType = (d: any) => void

export const timeLine: 'Timeline' = 'Timeline'

/**
 * Used on mount
 * @param format
 */
export function OpointCustomTimeFormat(format: string): any {
  return partialRight(OpointDate.customFormat, [format])
}

/**
 * Returns function for formatting date based on array of [format, test] doubles rules
 * Used on mount
 * @param formats
 * @returns {function(*=)}
 */
export function getMultiFormat(
  formats: Array<[(format: string) => any, (date: Object) => boolean]>,
): (date: Object) => string {
  return (date) => {
    let timeFormat
    formats.some(([format, test]) => (test(moment(date)) ? (timeFormat = format) && true : false))

    return timeFormat(date)
  }
}

/* --- Render charts functions --- */

/**
 * Used for rendering all charts
 */
export function mergeGroups(groups: any[]) {
  groups = groups?.map(([group, _]) => group)

  return {
    all() {
      return groups.reduce((mergedGroup, group) => mergedGroup.concat(group.all()), [])
    },
    size() {
      return groups.reduce((sumSize, group) => sumSize + group.size(), 0)
    },
  }
}

/**
 * Used for rendering all charts
 * @param chartType
 * @returns {number}
 */
export function getMaxCountByChartType(chartType: string) {
  return (
    {
      pieChart: 5,
      rowChart: 10,
      barChart: 10,
      lineChart: 10,
      customBubbleChart: 10,
      customDataTable: 50,
    }[chartType] || 1000
  )
}

/**
 * Returns an ideal scale and units functions for given dimension
 * Used for rendering all charts
 * @param dimension
 * @param keyAccessor function(key): key
 * @returns {{scale: *, units: {round: (function(*): *), range: (function(*, *): *), offset: (function(*, *): *)}}}
 */
export function getScaleAndUnits(dimension: Dimension, keyAccessor: KeyAccessorType, keyRounder: KeyRounderType) {
  let scale: any = (d) => d
  let units = {
    floor: (d) => d, // groupValue function
    range: (from, to, domain) => domain, // xUnits function
    offset: (to, domain) => domain,
  }

  if (dimension.bottom(1).length === 0) {
    throw new Error('empty dimension')
  }
  let minDim = dimension.bottom(1)[0][dimension.by]
  let maxDim = dimension.top(1)[0][dimension.by]

  if (Array.isArray(minDim)) {
    ;[minDim] = minDim
  }
  if (Array.isArray(maxDim)) {
    ;[maxDim] = maxDim
  }

  switch (dimension.type) {
    case 'date': {
      units = computeTimeUnits(dimension, keyAccessor, keyRounder)
      minDim = new Date(+minDim)
      maxDim = new Date(+maxDim)
      scale = d3
        .scaleTime()
        .domain([minDim, units.offset(maxDim, 0)])
        .nice()
      break
    }
    case 'string': {
      units.range = dc.units.ordinal

      const domain = uniq(dimension.top(Infinity)?.map((d) => d[dimension.by]))

      scale = d3.scaleOrdinal().domain(domain)

      scale.rangeBands = (interval, padding, outerPadding) => {
        scale.range(interval)
        scale.padding(padding)
        scale.paddingOuter(outerPadding)
      }
      scale.rangeBand = scale.bandwidth
      break
    }
    case 'number': {
      units.range = dc.units.integers
      scale = d3.scaleLinear()
      maxDim += 1
      scale.range([minDim, maxDim])
      break
    }
    default:
      throw new Error(`unknown type of dimension value - ${typeof minDim} of dimension by ${dimension.by}`)
  }

  return { scale, units }
}

/**
 * Used for rendering all charts
 */
export function percents(getMax: () => number, val?: number) {
  return !val ? '0%' : `${Math.round((val * 100) / getMax())}%`
}

/**
 * This ensures, that data with complex dimension are sorted correctly,
 * (otherwise dates are compared as strings)
 * Used for rendering: Series Curves, Series Area, Series Bars, Time Table
 * @param dimensionArray
 * @returns {string}
 */
export function complexDimToSingleValue(dimensionArray: number[]) {
  const zeroes = '000000000000000'
  const fillZeroes = (number) => (zeroes + number).slice(zeroes.length)

  return fillZeroes(dimensionArray[DIMENSION_KEY_I]) + fillZeroes(dimensionArray[SERIES_KEY_I])
}

/**
 * Crossfilter's group method does not works properly on complex dimension,
 * so we need to group values manually emulates wanted behavior of
 * dimension.group(groupValue).reduceSum(value)
 * Used for rendering: Series Curves, Series Area, Series Bars, Time Table
 * @param dimension
 * @param groupValue - grouping function
 * @param sumValue - sum function
 */
export function groupAndSumComplexDimension(
  dimension: Dimension,
  groupValue: (oldKey: number[]) => number[],
  sumValue: (d: any) => number,
) {
  return {
    all() {
      const rounded = dimension
        .group()
        .reduceSum(sumValue)
        .all()
        ?.map((d) => {
          d.key = groupValue(d.key) // we do not use R.evolve here for better performance

          return d
        })
      this.rounded = rounded

      const reduced = rounded.reduce((reducedBySeries, d) => {
        const seriesKey = d.key[SERIES_KEY_I]

        if (!reducedBySeries[seriesKey]) {
          reducedBySeries[seriesKey] = []
          reducedBySeries[seriesKey].push(d)
        } else {
          const lastInSeries = reducedBySeries[seriesKey][reducedBySeries[seriesKey].length - 1]

          if (lastInSeries.key[DIMENSION_KEY_I] === d.key[DIMENSION_KEY_I]) {
            lastInSeries.value += d.value
          } else {
            reducedBySeries[seriesKey].push(d)
          }
        }

        return reducedBySeries
      }, {})

      return flatten(values(reduced))
    },
    top(n) {
      return this.all()?.filter((_, i) => i < n)
    },
    size() {
      return this.rounded.length + 1
    },
  }
}

/**
 * Decides which timeUnit we should use as interval for a given data set
 * so number of bins (bars in histogram) is in sane range (~15..30)
 * Used for rendering: Series Curves, Series Area, Series Bars, Time Table
 * @param timeDimension
 * @param keyAccessor
 * @param keyRounder
 * @returns timeUnit function
 */
export function computeTimeUnits(timeDimension: Dimension, keyAccessor: KeyAccessorType, keyRounder: KeyRounderType) {
  // returns number of boundaries in interval given by timeUnit function (d3-time Interval)
  const getTimeSize = (timeUnit) => {
    const all = timeDimension.group(keyRounder(timeUnit)).all()
    if (all.length === 0) {
      return 0
    }
    const firstGroup = all[0]
    const lastGroup = all[all.length - 1]

    return timeUnit.count(new Date(keyAccessor(firstGroup)), new Date(keyAccessor(lastGroup)))
  }

  // same as timeUnit.every(number)
  // but solves issue where
  // timeUnit.every(x).count does not exist for x > 1
  const every = (number, timeUnit) => {
    if (number > 1) {
      timeUnit = timeUnit.every(number)
    }

    if (!timeUnit.count) {
      timeUnit.count = (from, to) => timeUnit.range(from, to).length
    }

    return timeUnit
  }

  // a reasonable count/group: we start with days: 15 to 41
  let timeUnit = every(1, d3.timeDay)
  let timeSize = getTimeSize(timeUnit)

  // over 1094 days: go over to years, 3 and above
  if (timeSize > 1094) {
    timeUnit = every(1, d3.timeYear)
  }
  // over 280 days, go over to months, between 8 and 36
  else if (timeSize > 280) {
    timeUnit = every(1, d3.timeMonth)
  }
  // over 42 days, go over to weeks: 7 to 40 bins
  else if (timeSize >= 42) {
    timeUnit = every(1, d3.timeWeek)
  }

  // between 15 and 41 days we do nothing at all and use the default

  // 7 to 14 days, go down to 12 hour bins: 14 to 28 bins
  else if (timeSize >= 7 && timeSize <= 14) {
    timeUnit = every(12, d3.timeHour)
  }
  // 4 to 6 days, go down to 6 hour bins: 16 to 24 bins
  else if (timeSize >= 4) {
    timeUnit = every(6, d3.timeHour)
  }
  // 3 days, go down to 3 hour bins: 17 to 24 bins
  else if (timeSize === 3) {
    timeUnit = every(3, d3.timeHour)
  }
  // 2 days or less: go down to hours: 12 to 48 bins
  else if (timeSize <= 2) {
    timeUnit = every(1, d3.timeHour)
    timeSize = getTimeSize(timeUnit)

    // 7 to 12 hours, go down to 30 minute bins: 14 to 24 bins
    if (timeSize >= 7 && timeSize <= 12) {
      timeUnit = every(30, d3.timeMinute)
    }
    // 4 to 6 hours, go down to 15 minute bins: 12 to 24 bins
    else if (timeSize >= 4 && timeSize <= 6) {
      timeUnit = every(15, d3.timeMinute)
    }
    // 3 hours or less, go down to 5 minute bins: 14 to 24 bins
    else if (timeSize <= 3) {
      timeUnit = every(5, d3.timeMinute)
      timeSize = getTimeSize(timeUnit)

      // less than 14 * 5m = 70m, first go to 2 minute bins: 15 to 35 bins
      if (timeSize <= 14 && timeSize >= 6) {
        timeUnit = every(2, d3.timeMinute)
      }
      // less than 6 * 5m = 30m, go to 1 minute bins
      else if (timeSize < 6) {
        timeUnit = every(1, d3.timeMinute)
      }
    }
  }

  return timeUnit
}

/**
 * Create filtered fake group from given group and filter
 * Used for rendering: Series Curves, Series Area, Series Bars
 */
export function filterGroup(group: { all(): any[]; top(n: number): any; size(): number }, filter: (any) => any) {
  return {
    all() {
      return filter(group.all().slice(0))
    },
    top(n: number) {
      return this.all()?.filter((_, i: number) => i < n)
    },
    size() {
      return this.all().length + 1
    },
  }
}

/**
 * Used for rendering: Series Curves, Series Area, Series Bars
 */
export function topNKeys(dimensionFunc: (value: any, iterable?: boolean) => any, by: number, n: number) {
  // Excluding overlapping keys as they just don't work with crossfilter
  const tempDim = dimensionFunc((d) => d[by])
  const keys = tempDim.group().all()

  // Distributed the group keys values across each key, remove the group keys
  // and sort again
  const trueCountKeys = keys
    .reduce((acc, entry, i) => {
      if (entry.key.length === 1) {
        return acc
      }

      entry.key?.forEach((k) => {
        const i = acc.findIndex((e) => (e.key.length === 1 ? e.key[0] === k : false))
        if (i === -1) {
          acc.push({ key: [k], value: entry.value })
        } else {
          acc[i].value += entry.value
        }
      })

      return acc
    }, keys.slice(0))
    ?.filter((entry) => entry.key.length === 1)

  trueCountKeys.sort((a, b) => b.value - a.value)

  tempDim.dispose()

  return trueCountKeys.slice(0, n)?.map((d) => d.key)
}

/**
 * Creates a fake group (https://github.com/dc-js/dc.js/wiki/FAQ#fake-groups)
 * to ensure, that group contains all the bins that are presented in whole data set.
 * Used for rendering: Series Curves, Series Area, Series Bars
 * @param sourceGroup
 * @param units
 * @returns {{all: function}}
 */
export function fillMissingBinsComplex(
  sourceGroup: { all(): any[]; top(n: number): any; size(): number },
  units: { floor: (d: any) => any; range: (from: any, to: any, domain?: any) => any },
) {
  // NOTE: this implementation expecting dimension to be of type date,
  // with dates stored as timestamp

  const sortCompare = (a, b) => {
    // sort by dimension key first because dimension must be correctly naturally ordered
    if (a.key[DIMENSION_KEY_I] < b.key[DIMENSION_KEY_I]) {
      return -1
    }
    if (a.key[DIMENSION_KEY_I] > b.key[DIMENSION_KEY_I]) {
      return +1
    }
    // sort by series key to ensure only consecutive items may have identical key
    // and thus we may use simple filter in next function
    // to remove duplicities instead of unique function
    if (a.key[SERIES_KEY_I] < b.key[SERIES_KEY_I]) {
      return -1
    }
    if (a.key[SERIES_KEY_I] > b.key[SERIES_KEY_I]) {
      return +1
    }
    // sort by value from largest to ensure following duplicity
    // removing leaves items with non zero values
    if (a.value < b.value) {
      return +1
    }
    if (a.value > b.value) {
      return -1
    }

    return 0
  }

  const uniqueSortedFilter = (d, i, arr) =>
    i === 0 ||
    d.key[DIMENSION_KEY_I] !== arr[i - 1].key[DIMENSION_KEY_I] ||
    d.key[SERIES_KEY_I] !== arr[i - 1].key[SERIES_KEY_I]

  return {
    all() {
      // NOTE: we mustn't modify the original array, make sure it stays intact
      const result = sourceGroup.all()

      if (result.length === 0) {
        // When returning from comparison, group can be empty for a single frame.
        return sourceGroup.all()
      }

      const boundaries = new Set([
        // array of timestamps
        ...units
          .range(new Date(result[0].key[DIMENSION_KEY_I]), new Date(result[result.length - 1].key[DIMENSION_KEY_I]))
          ?.map((d) => +d),
        ...result?.map((d) => d.key[DIMENSION_KEY_I]),
      ])

      const seriesKeys = new Set(result?.map((bin) => bin.key[SERIES_KEY_I]))
      const binsToAdd = []

      boundaries?.forEach((boundary) => {
        seriesKeys?.forEach((seriesKey) => {
          const newKey = []
          newKey[DIMENSION_KEY_I] = boundary
          newKey[SERIES_KEY_I] = seriesKey
          newKey.valueOf = function () {
            return complexDimToSingleValue(this)
          }
          binsToAdd.push({ key: newKey, value: 0 }) // add zero-valued bin
        })
      })
      // concat creates new array so we can safely sort it in place in next step
      return result.concat(binsToAdd).sort(sortCompare)?.filter(uniqueSortedFilter)
    },
    top(n) {
      return this.all()?.filter((_, i) => i < n)
    },
    size() {
      return this.all().length
    },
  }
}

/**
 * // TODO prevent unwanted transitioning if possible
 * Used for rendering: Pie
 */
export function rotatePieChart(chart: any, degree: number) {
  chart.on('pretransition', (innerChart) => {
    innerChart.selectAll('.pie-slice-group .pie-slice').attr('transform', rotate(degree))
    innerChart.selectAll('.pie-label-group').attr('transform', rotate(degree))
  })
  chart.on('renderlet', (innerChart) => {
    innerChart.selectAll('.pie-label-group .pie-slice').attr('transform', rotate(-degree))
  })

  function rotate(deg: number) {
    return function () {
      const rot = `rotate(${deg}.0)`
      const transformations = (this.getAttribute('transform') || '').split(' ')
      if (transformations[transformations.length - 1] !== rot) {
        transformations.push(rot)
      }

      return transformations.join(' ')
    }
  }
}

/* --- Export charts functions --- */

/**
 * Recursively call func on each node element in node tree
 * Used when exporting to SVG or PNG
 * @param {ChildNode} node
 * @param {Function} func
 */
export function traverseDOM(node: ChildNode, func: (element: ChildNode) => void) {
  if (node?.nodeType === document.ELEMENT_NODE) {
    func(node)
    node = node.firstChild
    while (node) {
      traverseDOM(node, func)
      node = node.nextSibling
    }
  }
}

/**
 * Set computed style of given element as its style attribute
 * Used when exporting to SVG or PNG
 * @param {HTMLElement} element
 */
export function explicitlySetStyle(element: any) {
  if (element.nodeName === 'SCRIPT') {
    return
  }
  // Make a temporary element to compare default styles with
  const temp = document.createElement('svg')
  document.body.appendChild(temp)
  const tempStyle = window.getComputedStyle(temp)

  const elementStyle = window.getComputedStyle(element) // CSSStyleDeclaration
  const attrs = element.attributes // not an array, but NamedNodeMap
  const style = {} // plain object style - elementStyle is immutable so we need a copy

  for (let i = 0; i < elementStyle.length; i++) {
    const name = elementStyle[i]

    // Only set styles with non-default values
    if (!tempStyle[name] || tempStyle[name] !== elementStyle[name]) {
      style[name] = elementStyle.getPropertyValue(name)
    }
  }
  for (let i = 0; i < attrs.length; i++) {
    // remove those properties which are also svg attributes
    const attr = attrs[i]
    delete style[attr.name]
  }

  document.body.removeChild(temp)

  const styleStr = styleToCssString(style)
  element.setAttribute('style', styleStr)
}

/**
 * Create css string from given style
 * Used when exporting to SVG or PNG
 * @param {Object} style
 * @returns string
 */
export function styleToCssString(style: Object) {
  return reduce(
    (str, key) => {
      const val = style[key]

      return str + (val ? `${String(key)}: ${val};` : '')
    },
    '',
    keys(style),
  )
}

/**
 * Decides wether the chart should be exported to SVG, PNG or CSV, based on the format given.
 * @param title
 * @param format
 * @param chart
 */
export const saveFile = (
  title: string,
  format: 'svg' | 'png' | 'csv',
  chart: any,
  documents?: any,
  aspect?: any,
  type?: string,
) => {
  const filename = `${title}-${new Date().toISOString()}.${format}`

  switch (format) {
    case 'svg':
      getSvgBlob(title, chart, type).then((blob) => saveAs(blob, filename))
      break
    case 'png':
      getPngBlob(title, chart, type).then((blob) => saveAs(blob, filename))
      break
    case 'csv':
      getCsvBlob(documents, aspect).then((blob) => saveAs(blob, filename))
      break
    default:
      throw new Error(`Unknown image format ${format}`)
  }
}

/**
 * Convert chart to SVG blob resolving in promise
 * @returns {Promise}
 */
const getSvgBlob = (title, chart, type) => {
  const svgString = toSvgString(title, chart, type)

  return new Promise((resolve) => {
    resolve(
      new Blob([svgString], {
        type: 'image/svg+xml;charset=utf-8',
      }),
    )
  })
}

/**
 * Convert chart to PNG blob resolving in promise
 * @returns {Promise}
 */
const getPngBlob = (title, chart, type) => {
  const svgString = toSvgString(title, chart, type)
  const imgSrc = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`

  return new Promise((resolve) => {
    // import svg into image
    const img = document.createElement('img')

    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, img.width, img.height)
      ctx.drawImage(img, 0, 0)
      try {
        canvas.toBlob(resolve, 'image/png')
      } catch (e) {
        store.dispatch(buildAction(ActionTypes.SHOW_BAD_BROWSER_POPUP))
      }
    }
    img.setAttribute('src', imgSrc)
  })
}

export const toSvgString = (title, chart, type?) => {
  const svgElSelection = chart && chart.svg()

  // Applying missing styles.
  switch (true) {
    case title === 'Main-graph' || type === 'lineChart' || type === 'barChart' || title === 'main':
      applySeriesStyles(chart)
      break
    case type === 'pieChart':
      applyLegendStyles(chart)
      break
    case type === 'rowChart':
      applyRowStyles(chart, 'normal')
      break
  }

  if (!svgElSelection) {
    return null
  }

  svgElSelection.attr('title', title).attr('version', 1.1).attr('xmlns', 'http://www.w3.org/2000/svg')

  // Cloning the node, so that we won't interfere with the primary SVG elements.
  let svgElement

  if (chart._chartGroup === 'compare') {
    svgElement = svgElSelection.node()
  } else {
    svgElement = svgElSelection.node() && svgElSelection.node()?.cloneNode(true)
  }

  if (svgElement) {
    // set computed styles as style attribute
    traverseDOM(svgElement, explicitlySetStyle)

    svgElement = svgElement?.cloneNode(true)

    return new XMLSerializer().serializeToString(svgElement)
  }
}

export const dataToCsv = (documents, aspect) => {
  const sorted = documents.sort((a, b) => b.value - a.value)

  const csvString = sorted?.map((d) => {
    const trimmedName = keyToName(d.key, aspect)[0]
      ?.trim()
      .replace(/\n\s*\n/g, '')
      .replace(/,/g, '')

    return `${trimmedName}, ${d.value}`
  })

  return csvString.join('\r\n')
}

const keyToName = (key: number, aspect: any) => {
  const name = aspect?.aspectpart[key]?.names

  return name
}

/**
 * Export chart to CSV blob resolving in promise
 * @returns {Promise}
 */
const getCsvBlob = (documents, aspect) => {
  const cvsString = dataToCsv(documents, aspect)

  return new Promise((resolve) => {
    resolve(new Blob([cvsString]))
  })
}

/**
 * Hack to convert image into svg string
 * @param {String} context - base64 data image
 * @param {width, heigth} - image dimensions
 * @returns {String}
 */
export const datauriToSvgString = (context, { width, height }) => {
  return `<svg width="${width}" height="${height}" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
      <image width="${width}" height="${height}" xlink:href="${context}"/>
      </svg>`
}

/**
 * Get image dimenstions resolving in promise
 * @param {String} file - svg string
 * @returns {Promise}
 */
export const getImageDimensions = (file: string) =>
  new Promise((resolved, rejected) => {
    const img = new Image()

    img.src = file
    img.onload = () => {
      resolved({ width: img.width, height: img.height })
    }
  })

/**
 * Returns an empty svg string to prevent the Graph export.
 * This chart wouldn't be exported to pdf file but save its order in Map list
 */
export const emptySVGString = (getExportData, name, width) => {
  if (!getExportData) {
    return
  }

  getExportData('', {
    title: () => name,
    width: () => width,
    getName: () => name,
  })
}

export const setDropdownHeight = (dropdown: Element): void => {
  const SPACING = 20
  const { height, top } = dropdown.getBoundingClientRect()
  const bodyH = document.body.clientHeight
  // set lower height of dropdown in case of page overflow or leave original height
  // @ts-ignore
  dropdown.style.height = `${Math.min(bodyH - (top + SPACING), height)}px`
}
export const applyRowStyles = (chart, format: 'compare' | 'normal') => {
  if (chart) {
    // Adding styles to the different SVG elements
    const rowContent = chart.select('g.row-chart-content')
    // Hiding X axis line
    if (format === 'compare') {
      rowContent.select('g.axis').select('path.domain').attr('display', 'none')
      rowContent.selectAll('g.row').select('path.target').attr('stroke-width', '3px')
    } else {
      rowContent.select('path.domain').attr('display', 'none')
    }
    rowContent.selectAll('g.row').select('text').attr('font-family', 'Roboto')
    // Vertical lines
    const ticks = rowContent.select('g.axis').selectAll('g.tick')
    // Hiding small tick lines
    ticks.select('line').attr('display', 'none')
    ticks.select('line.grid-line').attr('fill', 'none').attr('stroke', '#E5E5E5').attr('opacity', 1)
    ticks.select('text').attr('fill', '#E5E5E5')
  }
}

/**
 * We're adding styles to the SVG for export reasons.
 * The styles have to be directly implemented on the elements, because our CSS styling aren't registered.
 * @param chart
 */
const applySeriesStyles = (chart) => {
  if (chart) {
    const chartContent = chart.select('svg')
    chartContent.selectAll('text').attr('font-family', 'Roboto').attr('color', '#001F4A')
    // Hiding tick lines
    chartContent.selectAll('g.tick').select('line').attr('display', 'none')
    // Show horizontal grid lines with the color, grey.
    chartContent.select('g.grid-line').selectAll('line').attr('stroke', '#E5E5E5')
    // Hide vertical lines
    chartContent.select('g.vertical').selectAll('line').attr('opacity', 0)
    // Hide selection area
    chartContent.select('g.brush').select('rect.selection').attr('opacity', 0).attr('stroke', 'transparent')
    // Only the Timeline has the brush on.
    if (chart.brushOn()) {
      // Hide the X-axis (ticks with dates)
      chartContent.select('g.x').selectAll('g').attr('opacity', 0)
    }

    // Removing dots from lines (Series Curves)
    chartContent
      .selectAll('g.sub')
      .selectAll('circle.dot')
      .attr('fill-opacity', '1e-06')
      .attr('stroke-opacity', '1e-06')
    // (Series Area)
    chartContent
      .selectAll('g.dc-tooltip')
      .selectAll('circle.dot')
      .attr('fill-opacity', '1e-06')
      .attr('stroke-opacity', '1e-06')

    // Hiding black fill and adding thickness to the lines.
    chartContent.selectAll('g.stack').select('path.line').attr('fill', 'none').attr('stroke-width', '2.5px')

    // X axis
    chartContent.select('g.x').attr('opacity', 0.4).select('path.domain').attr('opacity', 0.4)
    // Y axis
    chartContent.select('g.y').attr('opacity', 0.4).select('path.domain').attr('display', 'none')

    applyLegendStyles(chart)
  }
}

/**
 * We're adding styles to the SVG-legend for export reasons.
 * The styles have to be directly implemented on the elements, because our CSS styling aren't registered.
 * @param chart
 */
const applyLegendStyles = (chart) => {
  if (chart) {
    chart.selectAll('g.dc-legend-item').select('rect').attr('display', 'none')
    chart.selectAll('g.dc-legend-item').selectAll('text').attr('font-family', 'Roboto')
  }
}

/**
 * Creates a string, showing the hour interval, articles have been published.
 * @param hour
 * @returns {string} String
 */
export const timeWidgetHourInterval: (hour: number, applyZeroes?: boolean) => void = (
  hour: number,
  applyZeroes?: boolean,
) => {
  const endOfRange = hour === 23 ? 0 : hour + 1
  if (applyZeroes) {
    if (hour >= 10) {
      return `${hour}:00-${endOfRange}:00`
    } else {
      return `0${hour}:00-${endOfRange}:00`
    }
  } else {
    return `${hour}-${endOfRange}`
  }
}

/**
 * Renders and calculates the positions of the unselected areas
 */
export const renderCustomOverlay: (chart, compositeChart: boolean) => void = (chart, compositeChart: boolean) => {
  const height = 125
  const yPosition = compositeChart ? 0 : 1
  const brush = chart.select('svg').select('g.brush')

  if (compositeChart) {
    // Hide selection area
    brush.select('rect.selection').attr('opacity', 0).attr('stroke', 'transparent')
  }

  // Removing before adding custom unselected overlays, so that we don't have multiple of the same element
  brush.select('rect.unselected-w')?.remove()
  brush.select('rect.unselected-e')?.remove()

  const totaltWidth = brush.select('rect.overlay')._groups[0][0].attributes.width.value
  const positionOfHandleW = brush.select('rect.handle--w')._groups[0][0].attributes.x?.value
  const positionOfHandleE = brush.select('rect.handle--e')._groups[0][0].attributes.x?.value

  brush
    .append('rect')
    .attr('class', 'unselected-w')
    .attr('id', 'unselected-w-id')
    .attr('height', height)
    .attr('x', 0)
    .attr('y', yPosition)
    .attr('width', parseInt(positionOfHandleW))
    .attr('fill', '#F2F1F2')
    .attr('opacity', 0.7)

  brush
    .append('rect')
    .attr('class', 'unselected-e')
    .attr('id', 'unselected-e-id')
    .attr('height', height)
    .attr('x', parseInt(positionOfHandleE) + 2)
    .attr('y', yPosition)
    .attr('width', parseInt(totaltWidth) - parseInt(positionOfHandleE))
    .attr('fill', '#F2F1F2')
    .attr('opacity', 0.7)
}

const getLayers = (chart, list: string, parentChart?) => {
  const chartBody = parentChart ? parentChart.chartBodyG() : chart.chartBodyG()
  chartBody.select(`g.${list}`).remove()

  const layersList = chartBody.append('g').attr('class', list)

  const layers = layersList.data(chart.data())

  return layers
}

export const addDataPointsToLineChart: (chart, compositeChart: boolean, color?: string) => void = (
  chart,
  compositeChart: boolean,
  color?: string,
) => {
  const circleLayers = getLayers(chart, 'circle-list')

  // Hide the list, we're extracting data from.
  const labelPoints = chart.select('svg').select('g.stack-list').selectAll('text.lineLabel').attr('display', 'none')

  const temp: any[] = []
  if (!compositeChart) {
    if (labelPoints._groups && labelPoints._groups.length > 0) {
      Array.from(labelPoints._groups[0])?.forEach((element: any) => {
        temp.push(element.attributes.y?.value)
      })
    }
  }

  let filters = []
  let startFilter
  let endFilter

  if (chart.filters().length > 0) {
    filters = chart.filters()[0]
    startFilter = filters[0]
    endFilter = filters[1]
  }

  circleLayers.each(function (d) {
    const layer = d3.select(this)
    const circle = layer.selectAll('circle.lineCircle').data(d.values, dc.pluck('x'))

    circle
      .enter()
      .append('circle')
      .attr('class', 'lineCircle')
      .attr('cx', (d) => dc.utils.safeNumber(chart.x()(d.x)))
      .attr('cy', (d, i) => {
        if (compositeChart) {
          const y = chart.y()(d.y + d.y0)

          return dc.utils.safeNumber(y)
        } else {
          return parseInt(temp[i]) + 4
        }
      })
      .attr('fill', '#F8F8F8')
      .attr('r', 7)

    circle
      .enter()
      .append('circle')
      .attr('class', 'lineCircle')
      .attr('cx', (d) => dc.utils.safeNumber(chart.x()(d.x)))
      .attr('cy', (d, i) => {
        if (compositeChart) {
          const y = chart.y()(d.y + d.y0)

          return dc.utils.safeNumber(y)
        } else {
          return parseInt(temp[i]) + 4
        }
      })
      .attr('fill', color)
      .attr('opacity', (d) => {
        if (d.x === 24 && compositeChart) {
          return 0
        }

        if (endFilter) {
          if (d.x >= startFilter && d.x < endFilter) {
            return 1
          } else {
            return 0.4
          }
        }
      })
      .attr('r', 5)
  })
}

export const addHoverZonesToChart: (chart, isLineChart: boolean, parentChart?) => void = (
  chart,
  isLineChart: boolean,
  parentChart?,
) => {
  const hoverLayers = getLayers(chart, 'hover-list', parentChart)

  const layerLength = Array.from(hoverLayers._groups)[0][0].__data__.values.length
  const zoneWidth = (1 / layerLength) * 100

  hoverLayers.each(function (h) {
    const layer = d3.select(this)
    const hoverZone = layer.selectAll('line.hoverZone').data(h.values, dc.pluck('x'))

    let halfBarWidth = null
    let barWidth = null
    if (!isLineChart) {
      const bar = Array.from(chart.select('rect.bar')._groups)[0][0]
      barWidth = bar && parseInt(bar.attributes.width?.value)
      halfBarWidth = barWidth / 2
    }

    hoverZone
      .enter()
      .append('line')
      .attr('class', 'hoverZone')
      .attr('x1', (d) => dc.utils.safeNumber(chart.x()(d.x) + (halfBarWidth && halfBarWidth)))
      .attr('x2', (d) => dc.utils.safeNumber(chart.x()(d.x) + (halfBarWidth && halfBarWidth)))
      .attr('y1', 120)
      .attr('y2', 0)
      .attr('stroke-width', isLineChart ? `${zoneWidth}%` : `${barWidth + 10}`)
  })
}

export const moveElementDown: (element, parentElement) => void = (element, parentElement) => {
  if (element.nextElementSibling) {
    element.parentNode.insertBefore(element, parentElement.lastChild)
    moveElementDown(element, parentElement.lastChild)
  } else {
    return
  }
}

/**
 * Applies styling, whenever a row is selected.
 * @param chart Chart object
 * @param filterKeys  Array of frequencyTable filters
 */
export const applyFrqTableStyling: (chart, filterKeys) => void = (chart, filterKeys) => {
  if (chart) {
    chart.selectAll('td._0').each(function (d) {
      const isChosen: boolean = filterKeys.some((key) => key === d.key)
      if (isChosen) {
        d3.select(this.parentNode).style('background-color', '#DDE0EA')
      }
      if (!isChosen && filterKeys.length > 0) {
        d3.select(this.parentNode).style('opacity', '0.6')
      }
    })
  }
}

/**
 * Resets the styling of selected and unselected rows.
 * @param chart Chart object
 */
export const resetFrqTableStyling: (chart) => void = (chart) => {
  chart.selectAll('tr.dc-table-row').style('background-color', 'transparent').style('opacity', '1')
}

export const customTimeFormat = getMultiFormat([
  // tighter labels for days and months
  [OpointCustomTimeFormat('HH:mm:ss.SSS'), (d: Dayjs) => d.millisecond() > 0],
  [OpointCustomTimeFormat('HH:mm:ss'), (d: Dayjs) => d.second() > 0],
  [OpointCustomTimeFormat('HH:mm'), (d: Dayjs) => d.minute() > 0],
  [OpointCustomTimeFormat('HH:mm'), (d: Dayjs) => d.hour() > 0],
  [OpointCustomTimeFormat('ddd D/M'), (d: Dayjs) => d.date() > 1],
  [OpointCustomTimeFormat('MMM'), (d: Dayjs) => d.month() > 0],
  [OpointCustomTimeFormat('YYYY/MMM'), () => true],
])

export const customTimeSliderFormat = getMultiFormat([
  // tighter labels for days and months
  [OpointCustomTimeFormat('HH:mm'), (d: Dayjs) => d.hour() > 0],
  [OpointCustomTimeFormat('ddd D/M'), (d: Dayjs) => d.date() > 1],
  [OpointCustomTimeFormat('MMM'), (d: Dayjs) => d.month() > 0],
  [OpointCustomTimeFormat('YYYY/MMM'), () => true],
])

/**
 * Takes a unix timestamp as parameter and converts it into a date string
 * @param date Unix timestamp
 * @returns Date string
 */
export const setDate: (date: number) => string = (date) => {
  return OpointDate.customFormat(date, 'DD. MMMM')
}

/**
 * Takes unix timestamps as parameters and converts it into a date/time string
 * @param startDate Unix timestamp
 * @param endDate Unix timestamp
 * @returns Date/Time string
 */
export const setStartDate: (startDate: number, endDate: number, isSameDate: boolean) => string = (
  startDate,
  endDate,
  isSameDate,
) =>
  isSameDate
    ? `${OpointDate.customFormat(startDate, 'DD. MMMM')} (${OpointDate.customFormat(
        startDate,
        'HH:mm',
      )} - ${OpointDate.customFormat(endDate, 'HH:mm')})`
    : OpointDate.customFormat(startDate, 'DD. MMMM')

export const fixateYPosAccordingTo: (destination, source) => void = (destination, source) => {
  const bounds = source.getBoundingClientRect()
  destination.style.top = `${bounds.top + bounds.height / 2}px`
}

/**
 * This function adjusts the width of the SVG, so that we're able to display the full text of the legend, when exporting.
 * @param chart Chart object
 * @param width Width of the chart
 */
export const adjustSVGWidth: (chart: any, width: number) => void = (chart, width) => {
  if (!chart) {
    return
  }

  const jumpWidth = 70 // The legends texts, changes position temporarily, to make space for percentages. So this needs to be added.

  const legend = chart.select('svg').select('g.dc-legend')
  const legendNode = legend.node()

  if (!legendNode) {
    return
  }

  const legendTransformAttribute = legend.attr('transform')
  const legendYPositionString = legendTransformAttribute?.split('(')[1]?.split(' ')[0]
  const legendYPosition = parseInt(legendYPositionString)

  const legendWidth = legendNode?.getBoundingClientRect()?.width

  const newChartWidth = legendWidth + legendYPosition + jumpWidth

  if (width > newChartWidth) {
    return
  }

  chart.select('svg').attr('width', `${newChartWidth}`)
}
