import R from 'ramda'
import { createSelector } from 'reselect'

import { hiddenTag } from '../opoint/common/constants'

import { getTags as getState, getForm } from './rootSelectors'

export const getTags = createSelector(getState, (tagsState) => {
  const allTags = tagsState.list
  const filteredTags = allTags.filter((tag) => tag.name !== hiddenTag)

  return filteredTags
})

export const getTagsWithType = createSelector(getTags, (list) => R.map(R.assoc('type', 'tag'), list))

export const getLastUsedTagId = createSelector(getState, (tagsState) => tagsState.lastUsedTagId)

export const getFilteredTags = createSelector(getTags, (tags) =>
  // @ts-ignore
  R.memoize((type) => R.filter(R.propEq('type', type), tags)),
)

export const isEditTagOpened = createSelector(getForm, (form) => form?.tag?.edit !== undefined)

export const getAddTag = createSelector(getForm, (form) => form?.tag?.add?.values)

export const getEditTag = createSelector(getForm, (form) => form?.tag?.edit?.values)

export const getAddTagFolderId = createSelector(getState, (tagsState) => tagsState.folderId)
