import R from 'ramda'
import ReactDOM from 'react-dom'
import Rx from 'rx-dom'

import { articleWithCorrectedImagePaths } from '../../new-components/helpers/article'
import type { Article, ECBContent } from '../../new-components/types/article'
import config from '../common/config'
import { OpointTimestampToTimestamp } from '../common/time'
import type { UpdatedArticleData, AddArticleSuggestionMediatype } from '../flow'

export const TYPE_PREVIEW = 'preview'
export const TYPE_LISTING = 'listing'

export const articleId: (article: Article) => string = (article) => {
  return `${article?.id_site}_${article?.id_article}`
}

export const articleIdFromIds: (id_site: number, id_article: number) => string = (id_site, id_article) => {
  return `${id_site}_${id_article}`
}

/**
 * Given an article, this function returns all articles (incl. identical articles)
 * @sig Article -> Array<Article>
 */
export const getAllIdenticalArticles = R.ifElse(
  (article) => article.identical_documents && article.identical_documents.document.length,
  (article) => article.identical_documents.document,
  (article) => [article],
)

/**
 * @return list of articleId of all identical articles of given article
 */
export const articleIdenticalIds = (article: Article) => {
  return getAllIdenticalArticles(article)?.map(articleId)
}

export const eqArticles = R.eqBy(articleId)

export const articleIdToArticle: (articleId: string) => { id_site: string; id_article: string } = (articleId) => {
  const [id_site, id_article] = articleId.split('_')

  return { id_site, id_article }
}

/**
 * @return active identical article or null if no identical articles are available
 */
export const getIdenticalArticle = (identical, article: Article) => {
  const index = identical[articleId(article)]

  if (isNaN(index) || !article.identical_documents) {
    return null
  }

  return article.identical_documents.document[index]
}

export const ARTICLE_NORMAL = 0
export const ARTICLE_LOGIN = 1
export const ARTICLE_LOGIN_DOWNLOAD = 2
export const ARTICLE_NORMAL_SEPARATE_VIP = 3
export const ARTICLE_GALLERY = 4
export const ARTICLE_NOT_OLE = 5
export const ARTICLE_NOT_OLE_REDIR = 6
export const ARTICLES_BEFORE_END_TO_AUTOLOAD = 5
export const MAX_AUTOLOADED_ARTICLES = 50

/* eslint-disable-next-line max-len */
export const urlRegExp =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/

export const getArticleUrl = ({
  content_protected,
  orig_url,
  url,
}: {
  content_protected: number
  orig_url?: string
  url?: string
}) => {
  /**
   * @example orig_url: "http://kulturistika.ronnie.cz/c-25432-na-balaton-open-throwdown-2016-zavodili-i-cesi.html"
   * @example url: "http://redir.opoint.com/?key=ajiIFdAFBziAtzMZ1FoM"
   *
   * We return orig_url in case article is public or navigate to
   * redir page in case it's protected.
   * if content_protected is not from the interval [0,6]m return url
   *
   * @see https://trello.com/c/H3MyjB21/1274-articles-behind-paywalls-is-being-visible-to-the-users-the-wrong-way
   * @see https://trello.com/c/9EnBJiRG/1584-pay-wall-we-read-behind-the-wall-in-m3-but-not-in-app
   *
   */

  function isOrigUrlUsed(contentProtected: number) {
    switch (contentProtected) {
      case ARTICLE_LOGIN:
      case ARTICLE_LOGIN_DOWNLOAD:
      case ARTICLE_NOT_OLE_REDIR:
        return false
      case ARTICLE_NORMAL:
      case ARTICLE_NORMAL_SEPARATE_VIP:
      case ARTICLE_GALLERY:
      case ARTICLE_NOT_OLE:
        return true
      default:
        return true
    }
  }

  return isOrigUrlUsed(content_protected) ? orig_url : url
}

/**
 * Function return primary and secondary url for article headline
 * for translated article urls are swapped
 *
 * @param {String} GTranslate_url   URL for the translated article
 * @param {Integer} content_protected   Is the content protected
 * @param {string} orig_url   Original URL for the article
 * @param {Boolean} translated   Should the text be translated
 * @param {String} url   URL for the article
 * @returns {{ primary: string, secondary: string}}
 */
export const getHeaderUrls = ({
  GTranslate_url,
  content_protected,
  orig_url,
  translated,
  url,
}: {
  GTranslate_url?: string
  content_protected: number
  orig_url?: string
  translated?: boolean
  url?: string
}) => {
  const origUrl = getArticleUrl({ content_protected, orig_url, url })
  const gtUrl = GTranslate_url

  return translated ? { primary: gtUrl, secondary: origUrl } : { primary: origUrl, secondary: gtUrl }
}

/**
 * Function return language iso codes
 * original and destination if translated
 *
 * @param {String} GTranslate_url   URL for the translated article
 * @param {Object} language   Contains the name of the language to translate to
 * @param {Object} orig_language   Contains the name of the original language
 * @param {Boolean} translated   Should the text be translated
 * @returns {{orig: string, dest:? string}}
 */
export const getLangCodes = ({
  GTranslate_url,
  language,
  orig_language,
  translated,
}: {
  GTranslate_url?: string
  language: { text: string }
  orig_language: { text: string }
  translated?: boolean
}) => {
  if (translated) {
    return {
      orig: orig_language.text,
      dest: language.text,
    }
  }

  return {
    orig: language.text,
    dest: GTranslate_url ? GTranslate_url.match(/tl=([a-z]{2})/)[1] : null,
  }
}

/**
 * Funtion returns node reference of specific article from Array of references
 * @param  {Array} articlesRefs   Array of article refs
 * @param  {Article} article      Article object
 * @return {Node}                 Node reference for specific article
 */
export const getActiveArticleNode = (articlesRefs, article) => {
  return ReactDOM.findDOMNode(articlesRefs[articleId(article)])
}

/**
 * Function is used to decide if there is change of active article or not.
 * @param  {Node} container     Article listing container
 * @param  {Array} articles     Array of Article objects
 * @param  {Array} articlesRefs  Array of article nodes references
 * @param  {Integer} active     index of active article
 * @return {Integer}            returns relative change of active article index
 *                              or 0 if there is no change
 */
export const crossScrollTreshold = (container, articles, articlesRefs, active) => {
  const treshold = 75
  const activeArticle = articles[active]
  if (!activeArticle) {
    return null
  }
  const article = getActiveArticleNode(articlesRefs, activeArticle)
  // @ts-ignore
  const articleBox = article?.getBoundingClientRect()
  const containerBox = container?.getBoundingClientRect()

  if (articles.length === active + 1) {
    return null
  }

  // Change index when scrolling up
  if (articleBox?.top - treshold > containerBox?.top) {
    return getIndexChange(-1, active, articles, articlesRefs, containerBox, treshold)
  }

  // Change index when scrolling down
  if (articleBox?.bottom - treshold <= containerBox?.top) {
    return getIndexChange(1, active, articles, articlesRefs, containerBox, treshold)
  }

  return null
}

/**
 * function return index of new active article relative to current active
 * article
 * @param  {[Integer]} direction    direction of scroll (+1/-1) - +1 is down
 * @param  {[Integer]} activeIndex  index of current active article
 * @param  {[Array]} articles       Array of Article objects
 * @param  {[Array]} articlesRefs    Array of article references
 * @param  {[Object]} containerBox  bounding rectangle of article listing
 *                                  container
 * @param  {[Integer]} treshold     Size of transition area where active
 *                                  article change can happen, in px
 * @return {[Integer]}              difference between old and new active
 *                                  article index
 */
export const getIndexChange = (direction, activeIndex, articles, articlesRefs, containerBox, treshold) => {
  let delta = null

  for (let i = activeIndex + direction; i >= 0 && i < articles.length; ) {
    const a = getActiveArticleNode(articlesRefs, articles[i])
    // @ts-ignore
    const aBox = a?.getBoundingClientRect()

    if (R.gt(aBox?.bottom - treshold, containerBox.top) && R.lte(aBox?.top - treshold, containerBox.top)) {
      delta = i

      return delta
    }

    i += direction
  }

  return delta
}

export const suggestionMediatypesIntoSuggestionShape = R.map(
  ({ id_site: idSite, type, sitename }: AddArticleSuggestionMediatype) => ({
    id: idSite,
    name: `${type}: ${sitename}`,
    expandedMediatype: true,
  }),
)

export const addArticle = async (newArticle, access: 'customer' | 'global' | 'user') => {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/articles/create/'),
    method: 'post',
    body: JSON.stringify({ ...newArticle, access }),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// used in Add Article file upload
export const uploadArticleFile = (file: Object) => {
  // dissocPath is used to remove content-type property from headers. This way
  // browser can set this header by itself. It is easiest way how to set correct
  // boundary for file upload to work correctly
  const requestHeaders = R.merge(R.dissocPath(['headers', 'content-type'], config.request.getRequestHeaders()), {
    url: config.url.api('/articles/upload/'),
    method: 'post',
    body: file,
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

/**
 * Function copies input article to 1st position in
 * identical_documents.document array but without identical_documents prop
 * @param {Article} article - article to process
 * @return Article
 */
const removeIdentical = (article: Article): Article =>
  // @ts-ignore
  R.when(
    R.always(R.gt(R.length(article.identical_documents.document), 0)),
    R.evolve({
      identical_documents: {
        document: R.prepend(R.dissoc('identical_documents', article)),
      },
    }),
  )(article)

/**
 * Index tags by id
 * @param article
 */
export const indexTagsById = (article: Article): Article =>
  R.evolve({
    // @ts-ignore
    tags: R.indexBy(R.prop('id')),
  })(article)

/**
 * Create unique id of document and his own identical documents
 * @param article
 */
const indexBySiteAndArticleId = (article: Article): Article =>
  // @ts-ignore
  R.compose(
    R.assoc('id', articleId(article)),
    // @ts-ignore
    R.evolve({
      identical_documents: {
        document: R.map(indexBySiteAndArticleId),
      },
    }),
    // @ts-ignore
  )(article)

const normalizeTagsTimestamps = R.evolve({
  tags: R.map(
    R.evolve({
      sent: (timestamp) => OpointTimestampToTimestamp(timestamp) * 1000,
      equal_sent: (timestamp) => OpointTimestampToTimestamp(timestamp) * 1000,
    }),
  ),
})

/**
 * @sig Array<Article> -> Array<Article>
 */

// @ts-ignore
export const preprocessArticle = R.compose(
  removeIdentical,
  R.evolve({
    identical_documents: {
      document: R.map(indexTagsById),
    },
  }),
  indexTagsById,
  // @ts-ignore
  normalizeTagsTimestamps,
  indexBySiteAndArticleId,
  articleWithCorrectedImagePaths,
)

export const preprocessArticles = R.compose(R.map(preprocessArticle))

/**
 * Reducer to merge current identical object with new properties
 * @param  {Object} acc      identical object with indentical articles indexes
 * @param  {Article} article article to iterate over
 * @return {Object}          merged identical object
 */
export const identicalReducer = (acc, article) =>
  Object.keys(acc).includes(articleId(article))
    ? acc
    : R.gt(R.length(article?.identical_documents?.document), 0)
    ? R.merge(acc, { [articleId(article)]: 0 })
    : acc

/**
 * Updates article using update metadata provided
 */
export const updateArticle = async (
  article: Article & ECBContent,
  updatedArticleData: UpdatedArticleData & ECBContent,
): Promise<any> => {
  const { id_site, id_article } = article

  const updatedArticle = {
    body: updatedArticleData.body && updatedArticleData.body.text,
    summary: updatedArticleData.summary && updatedArticleData.summary.text,
    header: updatedArticleData.header && updatedArticleData.header.text,
    author: updatedArticleData.author,
    translated_header: updatedArticleData.translated_header,
    manual_summary: updatedArticleData.manual_summary,
  }

  const body = {
    id_site,
    id_article,
    ...updatedArticle,
  }

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    method: 'POST',
    url: config.url.api('/articles/edited/'),
    body: JSON.stringify(body),
    responseType: 'text', // backend returns nothing
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(() => ({
      ...article,
      ...updatedArticleData,
    }))
}

export const shareArticles = async (
  articles: Array<Article>,
  message: string,
  recipients: Array<any>,
  shareAttachment: boolean,
  title = 'Shared articles',
  templateId = 11,
) => {
  const body = {
    articles,
    message,
    recipients,
    shareAttachment,
    templateId,
    title,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }

  const { headers } = await config.request.getRequestHeaders()

  const requestHeaders = R.merge(
    { headers },
    {
      method: 'POST',
      url: config.url.api('/articles/share/'),
      body: JSON.stringify(body),
    },
  )

  return Rx.DOM.ajax(requestHeaders).toPromise()
}

/**
 * Function calculates whether article should have sticky footer
 */
export const shouldHaveStickyTags: (content: HTMLElement, text: HTMLElement, footer: HTMLElement) => boolean = (
  content,
  text,
  footer,
) => {
  const footerHeight = footer && footer.getBoundingClientRect().height
  const contentMeasure = content.getBoundingClientRect()
  const OFFSET = 300

  if (contentMeasure.height - (footerHeight || 0) < OFFSET) {
    return false
  }

  const windowInnerHeight = window.innerHeight

  return (
    !!content &&
    !!text &&
    !!footer &&
    windowInnerHeight - text.getBoundingClientRect().top > OFFSET &&
    contentMeasure.bottom + footerHeight > windowInnerHeight
  )
}

export const getGroupsOfIdMediatypes = async (sitesIds: string) => {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/site_search/site_type/?sites=${sitesIds}`),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export enum LogActions {
  ArticleConsumed = 2,
  ArticleConsumed3Seconds = 3,
  ArticleConsumed10Seconds = 4,
  ArticleConsumedClick = 5,
  PDFViewed = 6,
  MediaClipPlayed = 7,
  ImageClicked = 8,
  ArticleAddedToTag = 9, //TODO: This one hasn't been implemented yet
}

/**
 * Sends information about article read
 * @param article - article that was read
 * @param action - action that needs to be logged
 * @returns void
 */
export const logArticleAction = async ({
  id_site,
  id_article,
  action,
}: {
  id_site: number
  id_article: number
  action: LogActions[]
}): Promise<any> => {
  //if (isDev) Promise.resolve()

  if (!id_article || !id_site) {
    return null
  }

  const request = R.merge(await config.request.getRequestHeaders(), {
    method: 'POST',
    url: config.url.api('/articles/log_visited/'),
    body: JSON.stringify({ id_site, id_article, action }),
  })

  return Rx.DOM.ajax(request)
    .toPromise()
    .then(({ response }) => response)
}

/**
 * Updates article headline or manual summary
 */
export const updateECBArticle = async (article: Article, updatedArticleData: ECBContent): Promise<any> => {
  const { id_site, id_article } = article

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    method: 'PATCH',
    url: config.url.api(`/articles/${id_site}/${id_article}/`),
    body: JSON.stringify(updatedArticleData),
    responseType: 'text', // backend returns nothing
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(() => ({
      ...article,
      ...updatedArticleData,
    }))
}
