import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import type { Suggestion } from '../opoint/flow'
import { getMultipleSuggestions } from '../opoint/search'
import {
  fetchSentimentGroups,
  overrideArticleCompanySentiment,
  createSentimentGroup,
  updateSentimentGroup,
} from '../opoint/sentiment'
import { getSentimentGroups } from '../selectors/sentimentSelectors'
import { getSuggestionsLocale } from '../selectors/settingsSelectors'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const sentimentFetchEpic: (action$) => void = (action$) =>
  action$
    .ofType(ActionTypes.LOG_IN_SUCCESS, ActionTypes.IMPERSONATE_SUCCESS)
    .switchMap(() => Rx.Observable.of(buildAction(ActionTypes.SENTIMENT_FETCH_GROUPS)))

const sentimentFetchGroupsEpic: (action$) => void = (action$) =>
  action$
    .ofType(ActionTypes.SENTIMENT_FETCH_GROUPS)
    .switchMap(() =>
      Rx.Observable.fromPromise(fetchSentimentGroups()).map((sentimentGroups) =>
        buildAction(ActionTypes.SENTIMENT_FETCH_GROUPS_SUCCESS, sentimentGroups),
      ),
    )
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.SENTIMENT_FETCH_GROUPS_FAILURE)))

const overrideCompanySentimentEpic: (action$, { getState }) => void = (action$, { getState }) =>
  action$
    .ofType(ActionTypes.OVERRIDE_COMPANY_SENTIMENT)
    .switchMap(({ payload }) => {
      const state = getState()
      const { articles, id_group } = payload || {}
      const sentimentGroups = getSentimentGroups(state)
      const { id_group: id } = sentimentGroups[0] || {}

      const body = { id_group: id_group || id, articles }

      return Rx.Observable.fromPromise(overrideArticleCompanySentiment(body)).map(() =>
        buildAction(ActionTypes.OVERRIDE_COMPANY_SENTIMENT_SUCCESS, { articles }),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.OVERRIDE_COMPANY_SENTIMENT_FAILURE)))

const sentimentFetchEntitiesEpic: (action$: any, { getState }: any) => void = (action$, { getState }) =>
  action$
    .ofType(ActionTypes.SENTIMENT_FETCH_ENTITIES)
    .switchMap(({ payload: { searchterm } }) => {
      const state = getState()
      const suggestionsLocale = getSuggestionsLocale(state)
      const accessParameterWithEntitiesOnly = 65536

      return Rx.Observable.fromPromise(
        getMultipleSuggestions(searchterm, [], suggestionsLocale, [], undefined, accessParameterWithEntitiesOnly),
      ).map((response: { ent: { suggest: Suggestion[] } }) =>
        buildAction(ActionTypes.SENTIMENT_FETCH_ENTITIES_SUCCESS, response?.ent?.suggest || []),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.SENTIMENT_FETCH_ENTITIES_FAILURE)))

const sentimentCreateGroupEpic: (action$: any, { getState }: any) => void = (action$) =>
  action$
    .ofType(ActionTypes.SENTIMENT_CREATE_GROUP)
    .switchMap(({ payload: { groupName, ids } }) => {
      return Rx.Observable.fromPromise(createSentimentGroup({ name: groupName, ids })).map((response) =>
        buildAction(ActionTypes.SENTIMENT_CREATE_GROUP_SUCCESS, response),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.SENTIMENT_CREATE_GROUP_FAILURE)))

const sentimentUpdateGroupEpic: (action$: any, { getState }: any) => void = (action$) =>
  action$
    .ofType(ActionTypes.SENTIMENT_UPDATE_GROUP)
    .switchMap(({ payload: { groupId, ids } }) => {
      return Rx.Observable.fromPromise(updateSentimentGroup(groupId, ids)).map((response) =>
        buildAction(ActionTypes.SENTIMENT_UPDATE_GROUP_SUCCESS, response),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.SENTIMENT_UPDATE_GROUP_FAILURE)))

export default [
  sentimentFetchEpic,
  overrideCompanySentimentEpic,
  sentimentFetchEntitiesEpic,
  sentimentCreateGroupEpic,
  sentimentUpdateGroupEpic,
  sentimentFetchGroupsEpic,
]
