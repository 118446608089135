import { createSelector } from 'reselect'

import { getSentiment as getState } from './rootSelectors'

export const getManuallyOverriddenCompanySentiment = createSelector(
  getState,
  (sentimentsState) => sentimentsState.manualCompanySentiments,
)

export const getSentimentGroups = createSelector(getState, (sentimentState) => sentimentState.sentimentGroups)

export const getCompanySentimentGroups = createSelector(getState, (sentimentsState) => sentimentsState.sentimentGroups)

export const getSentimentSuggestions = createSelector(getState, (sentimentsState) => sentimentsState.suggestions)
