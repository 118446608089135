import Rx from 'rxjs'

import { LOGOUT, SERVER_NOT_RESPONDING, TOKEN_EXPIRED } from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { history } from '../history'

export const pushLocation = (location: string) => {
  history.push(location)

  // Observable always need to return a downstream action
  // Since we want to bail and let the location listener do all the action we're
  // returning something that isn't picked up by any reducers or observables
  return Rx.Observable.of({ type: 'EMPTY' })
}

export const logOutOnExpiredToken = (val) =>
  val.status === 401 ? Rx.Observable.of(buildAction(LOGOUT), buildAction(TOKEN_EXPIRED)) : Rx.Observable.throw(val)

export const serverIsDown = (val) =>
  val.status === 0 || val.status >= 500
    ? Rx.Observable.of(buildAction(SERVER_NOT_RESPONDING))
    : Rx.Observable.throw(val)
