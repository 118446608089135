import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import { folderTraits } from '../constants/folders'
import { getFolderNameAndIcon } from '../new-components/helpers/common'
import { Action, Folder } from '../opoint/flow'

type FoldersState = {
  folders: Array<Folder>
  foldersTree: any[]
}

export const initialState: FoldersState = {
  folders: [],
  foldersTree: [],
}

const getFolderPermission = (folder) => {
  const isRegularFolder = folderTraits[folder?.traits] === 'regular'
  const isCuratedFolder = folderTraits[folder?.traits] === 'curated'
  const isNonCuratedFolder = folderTraits[folder?.traits] === 'nonCurated'

  // Default profiles folder
  if (folder.type === 0 && isRegularFolder) {
    return 'PROFILE_MODULE'
  }

  // Default tags folders
  if ((isCuratedFolder || isNonCuratedFolder) && isRegularFolder) {
    return 'TAG_MODULE'
  }

  // Default statistics folder
  if (folder.type === 6) {
    return 'STATISTICS_MODULE'
  }
}

const foldersReducer: (
  state: FoldersState,
  { type, payload }: Action<{ isECBUser: boolean; folders: Array<Folder>; regularFolderNames: Array<string> }>,
) => void = (
  state: FoldersState = initialState,
  { type, payload }: Action<{ isECBUser: boolean; folders: Array<Folder>; regularFolderNames: Array<string> }>,
) => {
  switch (type) {
    case Actions.FETCH_FOLDERS_SUCCESS: {
      const { isECBUser, folders } = payload

      folders?.forEach((folder) => {
        const { name, icon } = getFolderNameAndIcon(folder, isECBUser)
        folder.name = name
        folder.icon = icon
        folder.module = getFolderPermission(folder)
      })

      return R.assoc('folders', folders)(state)
    }

    case Actions.CREATE_FOLDERS_TREE_SUCCESS: {
      return R.assoc('foldersTree', payload)(state)
    }

    default:
      return state
  }
}

export default foldersReducer
