import { t } from '@transifex/native'
import { useRef, useEffect } from 'react'

import { folderTraits } from '../../constants/folders'
import { ITEM_SIZE } from '../../new-components/mainMenu/common/constants'
import { Alert, Feed, Folder, StatisticsView, Tag as TagFlow, Trash } from '../../opoint/flow'
import { IMAGE_URL_BASE } from '../constants/settings'
import { KeyValue } from '../types/settings'
import { AlertTag, Tag } from '../types/tag'
import { IconName } from '../types/theme'

export const executeIfFunction: (fn) => void = (fn) => {
  typeof fn === 'function' && fn()
}

// Transforms string of format 'key=val,key2=val2,...' to array of objects [{key: 'key', value: 'val'},{key: 'key2', value: 'val2'},...]
export const keyValueParse = (str?: string, sep = ','): KeyValue[] => {
  if (!str) {
    return []
  }

  return str.split(sep)?.map((item) => {
    const [key, value] = item.split('=')

    return {
      key,
      value,
    }
  })
}

// Transforms array of objects [{key: 'key', value: 'val'},{key: 'key2', value: 'val2'},...] to string of format 'key=val,key2=val2,...'
export const keyValueSerialize = (obj?: KeyValue[], sep = ','): string => {
  if (!Array.isArray(obj) || obj.length === 0) {
    return ''
  }

  return obj?.map(({ key, value }) => `${key}=${value}`).join(sep)
}

export const changeImageUrlBase = (str: string): string => {
  return str.replace(IMAGE_URL_BASE.old, IMAGE_URL_BASE.new)
}

// Custom hook for keeping previous props/state. Useful for comparing states in useEffect hook.
export const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })

  return ref.current as T
}

export const swapSignInString = (str: string): string => str.replace(/^(\+|-)/, (sign) => (sign === '-' ? '+' : '-'))

// Check whether a number or string is numeric
export const isNumber: (n: number | string) => boolean = (n: number | string) => {
  return !isNaN(Number(n))
}

// Abbreviate a number to millions (M) or thousands (K)
export const abbreviateNumber: (n: number) => void = (n: number) => {
  const abbreviate = (cap: number, letter: string) => Math.round((n / cap) * 10) / 10 + letter

  return n >= 1000000 ? abbreviate(1000000, 'M') : n >= 1000 ? abbreviate(1000, 'K') : n
}

/**
 * Sets the height of the scrollwindow.
 * @param list Array
 * @param availableHeight
 * @param visibleAmount How many tags, profiles etc.. should be shown in low resolution.
 * @returns {number} Returns the height of the scrollWindow.
 */
export const getListHeight: (
  list: Array<TagFlow | Tag | Trash | Alert | Feed | StatisticsView | AlertTag>,
  availableHeight: number,
  visibleAmount?: 5 | 10,
) => void = (list, availableHeight, visibleAmount = 5) => {
  const listHeightByItemCount = list.length * ITEM_SIZE
  const listHeight = listHeightByItemCount > availableHeight ? availableHeight : listHeightByItemCount

  if (availableHeight < ITEM_SIZE * visibleAmount && list.length > visibleAmount) {
    return ITEM_SIZE * visibleAmount
  } else if (list.length <= visibleAmount) {
    return listHeightByItemCount
  } else {
    return listHeight
  }
}

export const alterSoMeSummary: (summary, type, retweeted_user?) => string = (summary, type, retweeted_user?) => {
  const lastIndex = summary?.lastIndexOf(' ') ?? -1

  switch (true) {
    case type === 'link':
      return summary.substring(0, lastIndex)

    case type === 'retweet':
      let newSummary = summary
      const RTIncluded = summary?.includes('RT')

      const removedRT = summary.split(':')
      removedRT.shift() // Removing first element by using shift

      if (RTIncluded) {
        newSummary = removedRT.join(' ')
      }

      return t('Retweeted @{retweeted_user}: {newSummary}', { retweeted_user, newSummary })

    case type === 'reply':
      return t('Replying to {summary}', { summary })

    default:
      return summary
  }
}

export const MIN_CUSTOM_FOLDER_ID = 1000000000

export const getFolderNameAndIcon: (folder: Folder, isECBUser: boolean) => { name: string; icon: IconName } = (
  folder,
  isECBUser,
) => {
  const groupAndIconNames: {
    name: string
    typeIcons: { [index: string]: IconName }
    categoryIcons: { [index: string]: IconName }
  } = {
    name: folder.name,
    typeIcons: { 0: isECBUser ? 'briefcase' : 'star', 1: 'tag_alt', 2: 'sort', 6: 'statistics' },
    categoryIcons: {
      0: 'star',
      1: 'curated_profiles',
      2: 'non_curated_profiles',
      3: 'speech_bubble',
      4: 'front_pages',
      10: 'hashtag_small',
    },
  }

  const isDefaultFolder = folder.id < MIN_CUSTOM_FOLDER_ID

  const name = groupAndIconNames.name
  const icon =
    isDefaultFolder || folderTraits[folder.traits] === 'regular'
      ? groupAndIconNames.typeIcons[folder.type]
      : groupAndIconNames.categoryIcons[folder.traits]

  return { name, icon }
}
