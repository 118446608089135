// @ts-nocheck

import dayjs from 'dayjs'
import pick from 'lodash/pick'
import R from 'ramda'
import { createSelector } from 'reselect'

import {
  REPORT_STATUS,
  REPORT_STEP_CREATE_REPORT,
  REPORT_STEP_DOWNLOAD_SHARE,
  REPORT_STEP_SELECT_CONTENT,
  REPORT_STEP_SET_TEMPLATE,
  REPORT_STEP_SORT_CONTENT,
} from '../opoint/reports/index'
import {
  defaultStartEndDates,
  getStartAndEndFromId,
  isProfileSearch,
  isTagSearch,
  profileToSearchItems,
  searchDataToExpression,
  tagToSearchItems,
  isFilterCustomTimePeriod,
} from '../opoint/search'

import { getSelectedArticlesIds } from './articlesSelectors'
import { getRootProfiles } from './profilesSelectors'
import { getUI, getReports as getState } from './rootSelectors'
import {
  getMainSearchLine,
  getSearchMetaRangeEnd,
  getSearchMetaRangeStart,
  getSearchTimePeriod,
} from './searchSelectors'
import { getLastUsedTagId } from './tagsSelectors'
import { getTemplates, getXlsTemplates } from './templatesSelectors'

export const getHistory = ({ reports: { reportsHistory } }) => reportsHistory
export const getSource = ({ reports: { source } }) => source
export const getArticles = ({
  reports: {
    search: { articles },
  },
}) => articles
export const getArticlesCount = ({
  reports: {
    search: { count },
  },
}) => count
export const getGroups = ({
  reports: {
    search: { groups },
  },
}) => groups
export const getSearchContext = ({
  reports: {
    search: { context },
  },
}) => context
export const getLoading = ({
  reports: {
    search: { loading },
  },
}) => loading
export const getSaving = ({
  reports: {
    search: { savingSort },
  },
}) => savingSort
export const getTitle = ({ reports: { title } }) => title
export const getCompactMode = ({ reports: { compactListing } }) => compactListing
export const getReportObject = ({ reports: { reportObject } }) => reportObject
export const getReportHistoryObject = ({ reportsHistory: { reportObject } }) => reportObject
export const getShowDeleted = ({ reports: { showDeleted } }) => showDeleted
export const getFilterMode = ({ reports: { filterMode } }) => filterMode
export const getStep = ({ reports: { step } }) => step
export const getTemplate = ({ reports: { template } }) => template
export const getPreface = ({ reports: { preface } }) => preface
export const getFooter = ({ reports: { footer } }) => footer
export const getUpdateSoMe = ({ reports: { updateSoMe } }) => updateSoMe
export const getAutoTranslate = ({ reports: { autoTranslate } }) => autoTranslate

export const getDateTimeStart = createSelector(getState, (reports) => reports.dateTimeStart)

/*
  Order by start date is set:
    1. reports.dateTimeStart
    2. state.search.meta.rangeStart
    3. state.search.searchFilter.timePeriod.id
    4. default
*/
export const getStartDate = createSelector(
  getDateTimeStart,
  getSearchMetaRangeStart,
  getSearchTimePeriod,
  (dateTimeStart, searchMetaRangeStart, timePeriod) => {
    if (dateTimeStart) {
      return dateTimeStart
    }
    if (searchMetaRangeStart) {
      return searchMetaRangeStart
    }
    if (timePeriod?.id && isFilterCustomTimePeriod(timePeriod.id)) {
      return getStartAndEndFromId(timePeriod).startDate
    }

    return defaultStartEndDates().startDate
  },
)

export const getDateTimeEnd = createSelector(getState, (reports) => reports.dateTimeEnd)

/*
  Order by end date is set:
    1. reports.dateTimeEnd
    2. state.search.meta.rangeEnd
    3. state.search.searchFilter.timePeriod.id
    4. default
*/
export const getEndDate = createSelector(
  getDateTimeEnd,
  getSearchMetaRangeEnd,
  getSearchTimePeriod,
  (dateTimeEnd, searchMetaRangeEnd, timePeriod) => {
    if (dateTimeEnd) {
      return dateTimeEnd
    }
    if (searchMetaRangeEnd) {
      return searchMetaRangeEnd
    }
    if (timePeriod?.id && isFilterCustomTimePeriod(timePeriod.id)) {
      return getStartAndEndFromId(timePeriod).endDate
    }

    return defaultStartEndDates().endDate
  },
)

// set correct Flow
export const getDefaultSource = createSelector(
  getSource,
  getRootProfiles,
  getSelectedArticlesIds,
  getLastUsedTagId,
  getMainSearchLine,
  (source, rootProfiles, selectedArticles, lastUsedTagId, mainSearchLine) => {
    if (source && source.id) {
      return source
    }

    if (selectedArticles.length) {
      return { type: 'selected_articles' }
    }

    if (lastUsedTagId) {
      return { id: lastUsedTagId, type: 'tag' }
    }

    const searchItem = [
      {
        searchline: mainSearchLine,
        linemode: 'R',
      },
    ]

    if (isProfileSearch(searchItem)) {
      return { id: isProfileSearch(searchItem), type: 'profile' }
    }

    if (isTagSearch(searchItem)) {
      return { id: isTagSearch(searchItem), type: 'tag' }
    }

    return { type: 'current_search' }
  },
)

export const getActiveTemplate = createSelector(getState, getTemplates, ({ template }, templatesList) => {
  if (!templatesList || !template) {
    return null
  }

  const { id, type } = templatesList.find(({ id: templateId }) => templateId === template)

  return {
    id,
    type,
  }
})

export const isTagSource = createSelector(getSource, ({ type }) => type === 'tag')

export const useReportHistory = createSelector(
  getState,
  getSource,

  ({ useReportHistory }, source) => source && source.type === 'tag' && useReportHistory,
)

export const getCheckedTimestamps = createSelector(
  getState,

  ({ reportsHistory }) =>
    R.compose(
      R.map(({ stimestampUsed }) => stimestampUsed),
      R.filter(({ selected }) => !!selected),
      R.values,
    )(reportsHistory),
)

export const getCurrentSearch = createSelector(getMainSearchLine, searchDataToExpression)

export const getSearchItems = createSelector(getSource, getCurrentSearch, ({ type, id }, currentSearch) => {
  /* eslint-disable-line consistent-return */
  switch (type) {
    case 'current_search':
      return currentSearch
    case 'profile':
      return profileToSearchItems(id)
    case 'tag':
      return tagToSearchItems(id)
    default:
    // todo
  }
})

export const isReportFormValid = createSelector(
  useReportHistory,
  getTitle,
  getTemplate,
  getHistory,
  getEndDate,
  getStartDate,

  (useReportHistory, title, template, reportsHistory, dateTimeEnd, dateTimeStart) => {
    if (!template || !title) {
      return false
    }

    if (!useReportHistory && dateTimeStart && dateTimeEnd && !dayjs(dateTimeEnd).isBefore(dateTimeStart)) {
      return true
    }
    if (useReportHistory && reportsHistory && Object.values(reportsHistory).filter((h) => h.selected).length) {
      return true
    }

    return false
  },
)

export const getDisabledSteps = createSelector(isReportFormValid, getReportObject, (isValid, reportObject) => {
  if (!reportObject) {
    return isValid
      ? [REPORT_STEP_DOWNLOAD_SHARE]
      : [REPORT_STEP_SORT_CONTENT, REPORT_STEP_CREATE_REPORT, REPORT_STEP_DOWNLOAD_SHARE]
  }

  const { status } = reportObject

  switch (status) {
    case REPORT_STATUS.IN_PROGRESS:
      return [
        REPORT_STEP_SELECT_CONTENT,
        REPORT_STEP_SET_TEMPLATE,
        REPORT_STEP_SORT_CONTENT,
        REPORT_STEP_CREATE_REPORT,
        REPORT_STEP_DOWNLOAD_SHARE,
      ]
    case REPORT_STATUS.FINISHED:
    default:
      return []
  }
})

export const isXlsTemplate = createSelector(
  getState,
  getXlsTemplates,
  ({ template }, xlsTemplates) => !!R.find(({ id }) => id === template, xlsTemplates),
)
// returns ids of article and identical articles
/* eslint-disable-next-line no-confusing-arrow */
const reportAllArticleToIds = ({
  document: {
    id_article,
    id_site,
    identical_documents: { document: identicalArticles = [] },
  },
}) => {
  const formattedIdenticalArticles = identicalArticles
    .map((article) => pick(article, ['id_article', 'id_site']))
    .filter((article) => article.id_article !== id_article && article.id_site !== id_site)

  return formattedIdenticalArticles.length
    ? R.compose(R.append({ id_article, id_site }), R.map(R.dissoc('sim')))(formattedIdenticalArticles)
    : { id_article, id_site }
}

const reportArticleToIds = ({ document: { id_article, id_site } }) => ({ id_article, id_site })

export const getSortedIDs = createSelector(
  getArticles,
  R.compose(R.map(reportArticleToIds), R.reject(R.prop('deleted')), R.unnest, R.values),
)

// this is same as GetSortedIDs, but includes also ids of all identical articles
export const getAllSortedIDs = createSelector(
  getArticles,
  R.compose(R.unnest, R.map(reportAllArticleToIds), R.reject(R.prop('deleted')), R.unnest, R.values),
)

export const getDeletedArticles = createSelector(
  getArticles,
  R.compose(R.unnest, R.map(reportAllArticleToIds), R.filter(R.prop('deleted')), R.unnest, R.values),
)

export const getOpenedReport = createSelector(getUI, (UIState) => UIState.reportOpened)

export const getShareData = createSelector(getState, (reportState) => reportState.shareReportData)

export const getCreateReportIsTakingTooLong = createSelector(
  getState,
  (reportState) => reportState.createReportIsTakingTooLong,
)
