import R from 'ramda'
import Rx from 'rx-dom'

import config from '../common/config'
import type { NewTemplate, Template, TemplateModule, TemplateSettings, Widget } from '../flow'

/**
 * Constants
 */

export const TEMPLATE_TYPES = {
  PDF: 1,
  HTML: 3,
  XLS: 7,
  XML: 8,
  DOC: 9,
  PPT: 10,
  RSS: 11,
  JSON: 12,
}

export const EXCLUDED_TEMPLATES = [17]

export const TEMPLATE_TIME_PERIOD = [
  'New articles only',
  'Last 24 hours',
  'Today',
  'Yesterday',
  'Last 7 days',
  'Last 30 days',
  'This week',
  'This month',
  'This year',
  'Previous week',
  'Previous month',
  'Previous quarter',
]

/**
 * Functions
 */

export async function getTemplates(locale = 'en-GB') {
  const requestHeaders = R.compose(
    R.evolve({
      headers: {
        'accept-language': R.always(locale),
      },
    }),
    // @ts-ignore
    R.assoc('url', config.url.api('/templates/')),
    // @ts-ignore
  )(await config.request.getRequestHeaders())

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function getTemplate(templateId: number) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/templates/${templateId}/`),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function getTemplatePreview(templateId: number) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/templates/${templateId}/preview/`),
    method: 'POST',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function createTemplate(template: NewTemplate) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    method: 'POST',
    url: config.url.api('/templates/'),
    body: JSON.stringify(template),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function saveTemplate(templateSettings: TemplateSettings, templateId: number) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    method: 'PUT',
    url: config.url.api(`/templates/${templateId}/`),
    body: JSON.stringify(templateSettings),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function deleteTemplate(templateId: number) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    method: 'DELETE',
    url: config.url.api(`/templates/${templateId}/`),
    responseType: 'text',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

/**
 * Gets keys of groupedTemplates object as an array of keys with html format key on the first place
 * @param groupedTemplates is an object containing format keys (number)
 *   and their templates (arrays of objects)
 * @returns format keys array with html format on the first place
 */
// @ts-ignore
export function getGroupedTemplatesKeys(groupedTemplates: { [string]: Array<Template> }): Array<string> {
  return R.sortBy(R.compose(R.not, R.equals(TEMPLATE_TYPES.HTML.toString())))(R.keys(groupedTemplates)).filter(
    (type) => {
      // RSS is not supported by the back-end
      return type !== TEMPLATE_TYPES.RSS.toString()
    },
  )
}

/**
 * Rejects templates that are excluded (e.g. template 17 which still uses old generating script)
 * @param templates is an array containing template objects
 * @returns filtered templates
 */
export function filterExcludedTemplates(templates: Array<Template>): Array<Template> {
  // @ts-ignore
  return R.reject((template) => R.contains(template.id, EXCLUDED_TEMPLATES), templates)
}

/**
 * Returns object which contains all properties from objectB that are different from objectA
 * @param objectA original object
 * @param objectB updated object
 * @param objectC everything in this object must be in the final object
 * @returns new object
 */
export function objectDiff(objectA: any, objectB: any, objectC: any) {
  const keysB = Object.keys(objectB)
  const keysBLength = keysB.length
  const diff = {}
  let key

  for (let i = 0; i < keysBLength; i++) {
    key = keysB[i]

    if (objectC && objectC[key] !== undefined) {
      diff[key] = objectB[key]
    } else if (objectA[key] !== objectB[key]) {
      diff[key] = objectB[key]
    }
  }

  return diff
}

export function getUpdatedModule(module: TemplateModule, sortableModuleWidgets: Array<Widget>) {
  const sortableModuleWidgetsByName = R.groupBy(R.prop('name'), sortableModuleWidgets)

  return R.evolve({
    widgets: R.compose(
      R.sortBy(R.prop('sortIndex')),
      R.map((widget) =>
        R.mergeDeepWithKey(
          (key, left, right) => (key === 'sortIndex' ? right : left),
          widget,
          // @ts-ignore
          sortableModuleWidgetsByName[widget.name][0],
        ),
      ),
    ),
  })(module)
}
