import { t } from '@transifex/native'
import { createEpicMiddleware } from 'redux-observable'

import { CLEAR_ARTICLES, ROUTER_LOCATION_CHANGE } from './constants/actionTypes'
import configureEpics from './epics/configureEpics'
import errorEpics from './epics/web/errorEpics'
import navigationEpics from './epics/web/navigationEpics'
import uiEpics from './epics/web/uiEpics'

const webEpics = [...errorEpics, ...navigationEpics, ...uiEpics]

const rootEpic = configureEpics(
  {
    // ... platform deps in a future can go here
    translate: t,
  },
  webEpics,
)

export const epicMiddleware = createEpicMiddleware(rootEpic)

// This is a performance optimization, when we nagigate from one search
// to another, we clear article listing so that articles are not re-rendered
// before route change
export const routeChangedMiddleware = (store) => (next) => (action) => {
  if (!!action._isScalar) {
    action = action.value
    console.warn('-> action was scalar')
  }

  if (action.type === ROUTER_LOCATION_CHANGE) {
    // @ts-ignore
    window.Intercom?.('update')
  }

  if (action.type === ROUTER_LOCATION_CHANGE && action.payload.location && action.payload.location.pathname) {
    const { search: newSearch, pathname: newPathname } = action.payload.location
    const { search, pathname } = action.payload.previousLocation

    const isNewLocation =
      newSearch !== search || (!newPathname.startsWith(pathname) && !pathname.startsWith(newPathname))

    if (isNewLocation) {
      store.dispatch({ type: CLEAR_ARTICLES })
    }
  }

  return next(action)
}
