import { Observable, Observer } from 'rxjs'
import io from 'socket.io-client'

import type { Notification } from '../../opoint/flow'
import config from '../common/config'

export function notificationSocket() {
  return Observable.create(async (observer: Observer<Notification>) => {
    const query = `token=${await config.auth.getTokenString()}`

    const ioNotification = io.connect(config.url.api('/notifications'), {
      query,
      transports: ['websocket', 'polling', 'flashsocket'],
    })

    ioNotification.on('updated', (notification: Notification) => {
      observer.next(notification)
    })
  })
}
