// @ts-nocheck

import _Object$entries from 'babel-runtime/core-js/object/entries'
import _extends from 'babel-runtime/helpers/extends'
import invariant from 'invariant'
import PropTypes from 'prop-types'

import { SIZE_MAP } from './StyleConfig'

function curry(fn) {
  return function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key]
    }

    const last = args[args.length - 1]
    if (typeof last === 'function') {
      return fn.apply(undefined, args)
    }

    return function (Component) {
      return fn.apply(undefined, args.concat([Component]))
    }
  }
}

export function prefix(props, variant) {
  const bsClass = (props.bsClass || '').trim()
  !(bsClass != null)
    ? process.env.NODE_ENV !== 'production'
      ? invariant(false, 'A `bsClass` prop is required for this component')
      : invariant(false)
    : void 0

  return bsClass + (variant ? '-' + variant : '')
}

export var bsClass = curry(function (defaultClass, Component) {
  const propTypes = Component.propTypes || (Component.propTypes = {})
  const defaultProps = Component.defaultProps || (Component.defaultProps = {})

  propTypes.bsClass = PropTypes.string
  defaultProps.bsClass = defaultClass

  return Component
})

export var bsStyles = curry(function (styles, defaultStyle, Component) {
  if (typeof defaultStyle !== 'string') {
    Component = defaultStyle
    defaultStyle = undefined
  }

  const existing = Component.STYLES || []
  const propTypes = Component.propTypes || {}

  styles?.forEach(function (style) {
    if (existing.indexOf(style) === -1) {
      existing.push(style)
    }
  })

  const propType = PropTypes.oneOf(existing)

  // expose the values on the propType function for documentation
  Component.STYLES = existing
  propType._values = existing

  Component.propTypes = _extends({}, propTypes, {
    bsStyle: propType,
  })

  if (defaultStyle !== undefined) {
    const defaultProps = Component.defaultProps || (Component.defaultProps = {})
    defaultProps.bsStyle = defaultStyle
  }

  return Component
})

export var bsSizes = curry(function (sizes, defaultSize, Component) {
  if (typeof defaultSize !== 'string') {
    Component = defaultSize
    defaultSize = undefined
  }

  const existing = Component.SIZES || []
  const propTypes = Component.propTypes || {}

  sizes?.forEach(function (size) {
    if (existing.indexOf(size) === -1) {
      existing.push(size)
    }
  })

  const values = []
  existing?.forEach(function (size) {
    const mappedSize = SIZE_MAP[size]
    if (mappedSize && mappedSize !== size) {
      values.push(mappedSize)
    }

    values.push(size)
  })

  const propType = PropTypes.oneOf(values)
  propType._values = values

  // expose the values on the propType function for documentation
  Component.SIZES = existing

  Component.propTypes = _extends({}, propTypes, {
    bsSize: propType,
  })

  if (defaultSize !== undefined) {
    if (!Component.defaultProps) {
      Component.defaultProps = {}
    }
    Component.defaultProps.bsSize = defaultSize
  }

  return Component
})

export function getClassSet(props) {
  let _classes

  const classes = ((_classes = {}), (_classes[prefix(props)] = true), _classes)

  if (props.bsSize) {
    const bsSize = SIZE_MAP[props.bsSize] || props.bsSize
    classes[prefix(props, bsSize)] = true
  }

  if (props.bsStyle) {
    classes[prefix(props, props.bsStyle)] = true
  }

  return classes
}

function getBsProps(props) {
  return {
    bsClass: props.bsClass,
    bsSize: props.bsSize,
    bsStyle: props.bsStyle,
    bsRole: props.bsRole,
  }
}

function isBsProp(propName) {
  return propName === 'bsClass' || propName === 'bsSize' || propName === 'bsStyle' || propName === 'bsRole'
}

export function splitBsProps(props) {
  const elementProps = {}
  _Object$entries(props)?.forEach(function (_ref) {
    const propName = _ref[0],
      propValue = _ref[1]

    if (!isBsProp(propName)) {
      elementProps[propName] = propValue
    }
  })

  return [getBsProps(props), elementProps]
}

export function splitBsPropsAndOmit(props, omittedPropNames) {
  const isOmittedProp = {}
  omittedPropNames?.forEach(function (propName) {
    isOmittedProp[propName] = true
  })

  const elementProps = {}
  _Object$entries(props)?.forEach(function (_ref2) {
    const propName = _ref2[0],
      propValue = _ref2[1]

    if (!isBsProp(propName) && !isOmittedProp[propName]) {
      elementProps[propName] = propValue
    }
  })

  return [getBsProps(props), elementProps]
}

/**
 * Add a style variant to a Component. Mutates the propTypes of the component
 * in order to validate the new variant.
 */
export function addStyle(Component) {
  for (
    var _len2 = arguments.length, styleVariant = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1;
    _key2 < _len2;
    _key2++
  ) {
    styleVariant[_key2 - 1] = arguments[_key2]
  }

  bsStyles(styleVariant, Component)
}

export var _curry = curry
