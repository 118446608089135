import React, { lazy, Suspense } from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import { history } from './history'
import RequireAuthentication from './new-components/auth/RequireAuthentication'
import Callback from './pages/Callback'
import LoggedOut from './pages/logged-out'

// Pages
const App = lazy(() => import('./pages/app/App'))
const ArticleView = lazy(() => import('./pages/article/ArticleView'))
const MobileView = lazy(() => import('./pages/mobile/MobileView'))

const Routes: React.FC = () => (
  // @ts-ignore
  <Router history={history}>
    <Suspense fallback={null}>
      <Switch>
        {/* @ts-ignore */}
        <Route exact path="/callback">
          <Callback />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/article" render={() => <RequireAuthentication as={ArticleView} />} />
        {/* @ts-ignore */}
        <Route path="/mobile" render={() => <RequireAuthentication as={MobileView} />} />
        {/* @ts-ignore */}
        <Route exact path="/logged-out">
          <LoggedOut />
        </Route>
        {/* @ts-ignore */}
        <Route path="/" render={() => <RequireAuthentication as={App} />} />
      </Switch>
    </Suspense>
  </Router>
)

export default Routes
