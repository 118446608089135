import { applyMiddleware, createStore, compose } from 'redux'
import createLogger from 'redux-logger'

import { getEnvironmentVariable } from './helpers/environment'
import rootReducer from './reducers'
import { routeChangedMiddleware, epicMiddleware } from './redux-middleware'

// TODO https://github.com/zalmoxisus/redux-devtools-extension#usage
const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const enhancers = [routeChangedMiddleware, epicMiddleware]

// If diff is set to true, we are running into performance issues with large objects
const logger = createLogger({
  collapsed: true,
  diff: false,
})

const vercelEnv = getEnvironmentVariable('vercelEnv')

if (process.env.NODE_ENV === 'development' || vercelEnv === 'preview') {
  enhancers.push(logger)
}

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...enhancers)))

export default store
