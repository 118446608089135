// @ts-nocheck
import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import alerts from './alerts'
import analytics from './analytics'
import articles from './articles'
import auth from './auth'
import comments from './comments'
import contacts from './contacts'
import entities from './entityRepository'
import feeds from './feeds'
import folders from './folders'
import impersonation from './impersonation'
import notifications from './notifications'
import profiles from './profiles'
import { statisticsExport } from './reduxFormCustomReducers'
import reports from './reports'
import reportsHistory from './reportsHistory'
import search from './search'
import sentiment from './sentiment'
import settings from './settings'
import statistics from './statistics'
import tags from './tags'
import templates from './templates'
import trash from './trash'
import ui from './ui'
import watchIndex from './watchIndex'

const rootReducer = combineReducers({
  alerts,
  analytics,
  articles,
  auth,
  contacts,
  entities,
  feeds,
  form: form.plugin({ statisticsExport }),
  impersonation,
  notifications,
  profiles,
  reports,
  reportsHistory,
  search,
  settings,
  statistics,
  tags,
  templates,
  trash,
  ui,
  watchIndex,
  folders,
  sentiment,
  comments,
})

export default rootReducer
