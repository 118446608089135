import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import { Action, SentimentGroupType, Suggestion } from '../opoint/flow'

const sentimentObj = { null: 'Reset', 0: 'Neutral', 1: 'Negative', 2: 'Positive' }

type SentimentState = {
  sentimentGroups: Array<SentimentGroupType>
  manualCompanySentiments: any
  suggestions: Array<Suggestion>
}

export const initialState: SentimentState = {
  sentimentGroups: [],
  manualCompanySentiments: {},
  suggestions: [],
}

const overriddenCompanySentiments = {}

const sentimentReducer = (state: SentimentState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.SENTIMENT_FETCH_GROUPS_SUCCESS: {
      return R.assoc('sentimentGroups', payload)(state)
    }

    case Actions.SENTIMENT_FETCH_ENTITIES_SUCCESS: {
      return R.assoc('suggestions', payload)(state)
    }

    case Actions.SENTIMENT_UPDATE_GROUP_SUCCESS: {
      const { sentimentGroups } = state

      return R.assoc(
        'sentimentGroups',
        sentimentGroups.map((group: any) => (group.id_group === payload.id_group ? payload : group)),
      )(state)
    }

    case Actions.SENTIMENT_CREATE_GROUP_SUCCESS: {
      return R.assoc('sentimentGroups', [payload])(state)
    }

    case Actions.OVERRIDE_COMPANY_SENTIMENT_SUCCESS: {
      const { articles } = payload
      articles.forEach(({ id_article, id_site, manual_sentiment }) => {
        overriddenCompanySentiments[`${id_site}_${id_article}`] = sentimentObj[manual_sentiment]
      })

      return R.assoc('manualCompanySentiments', overriddenCompanySentiments)(state)
    }

    default:
      return state
  }
}

export default sentimentReducer
