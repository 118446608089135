// @ts-nocheck

import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import { reduceDuplicateSnippetOccurrence, simpleSort } from '../helpers/common'
import { articleId, eqArticles, identicalReducer, preprocessArticles } from '../opoint/articles/index'
import { COUNTRIES_CODES } from '../opoint/common/constants'
import { OpointTimestampToTimestamp } from '../opoint/common/time'
import type { Action, Article, XhrStatus } from '../opoint/flow'
import { filterId } from '../opoint/search/index'
import { TAG_TYPES, TAG_VALUES } from '../opoint/tags/index'

const getAffectedArticles = (mapFunction, articles, articlesList) =>
  R.compose(
    // index by id
    R.indexBy(R.prop('id')),
    R.map(mapFunction),
    // get affected articles based on payload request
    R.filter((f) =>
      R.contains(
        articleId(f),
        R.chain((a) => articleId(a), articles),
      ),
    ),
  )(articlesList)

type ArticlesStateType = {
  list: Array<Article>
  editedArticle: Article
  editedArticleXHRStatus: XhrStatus
  shareArticleXHRStatus: XhrStatus
  shareArticleMessage: string
  shareArticleAttachmentFlag: boolean
  editedArticleState: any
  updatedArticle: Article
}

export const initialState: ArticlesStateType = {
  list: [],
  identical: {},
  checked: {},
  deleted: {},
  active: {
    index: null,
    source: null,
  },
  addArticleForm: {},
  editedArticle: null,
  editedArticleXHRStatus: 'NOT_INITIATED',
  editedArticlePreviewMode: false,
  shareArticleXHRStatus: 'NOT_INITIATED',
  shareArticleMessage: '',
  shareArticleAttachmentFlag: true,
  editedArticleState: [],
  updatedArticle: null,
}

const editedArticlesArr = []

const articlesReducer = (state: ArticlesStateType = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.LOGOUT:
      return initialState

    case Actions.FETCH_ARTICLES:
      return R.assoc('list', [], state)

    case Actions.FETCH_ARTICLES_SUCCESS: {
      // TODO - this is the same as here PROFILE_EDITOR_PREVIEW_SUCCESS
      // we should factor this out.
      const interceptedDocuments = preprocessArticles(payload.response.searchresult.document)

      interceptedDocuments?.forEach((d) => {
        d.matches = reduceDuplicateSnippetOccurrence(d.matches)
      })

      return R.evolve({
        /* eslint-disable-next-line no-underscore-dangle */
        identical: R.reduce(identicalReducer, R.__, payload.response.searchresult.document),
        list: R.always(interceptedDocuments),
      })(state)
    }

    case Actions.FETCH_SINGLE_ARTICLE_FOR_TRANSLATION_SUCCESS: {
      const { response, translate, isIdentical, originalArticle } = payload
      const preprocessedArticle = preprocessArticles(response.searchresult.document)[0]

      if (isIdentical) {
        const {
          identical_documents: { document },
          identical_documents,
        } = originalArticle

        const newIdenticalDocument = document?.map((item) => {
          if (articleId(item) === articleId(preprocessedArticle)) {
            return { ...preprocessedArticle, translated: translate }
          }

          return item
        })

        return R.evolve({
          list: R.always(
            state.list?.map((item) => {
              if (articleId(item) === articleId(originalArticle)) {
                return {
                  ...originalArticle,
                  identical_documents: { ...identical_documents, document: newIdenticalDocument },
                }
              }

              return item
            }),
          ),
        })(state)
      }

      return R.evolve({
        list: R.always(
          state.list?.map((item) => {
            if (articleId(item) === articleId(preprocessedArticle)) {
              return { ...preprocessedArticle, translated: translate }
            }

            return item
          }),
        ),
      })(state)
    }

    case Actions.FETCH_SINGLE_ARTICLE_FOR_ARTICLE_VIEW_SUCCESS: {
      const { response } = payload
      const preprocessedArticle = preprocessArticles(response.searchresult.document)[0]

      return R.evolve({
        list: R.always([preprocessedArticle]),
      })(state)
    }

    case Actions.CLEAR_ARTICLES:
      return R.evolve({
        list: R.always([]),
        identical: R.always({}),
        checked: R.always({}),
        deleted: R.always({}),
        active: R.always({
          index: null,
          source: null,
        }),
      })(state)

    case Actions.FETCH_ARTICLES_WITH_WATCH_ID_SUCCESS: {
      const interceptedDocuments = preprocessArticles(payload.searchresult.document)

      interceptedDocuments?.forEach((d) => {
        d.matches = reduceDuplicateSnippetOccurrence(d.matches)
      })

      return R.evolve({
        /* eslint-disable-next-line no-underscore-dangle */
        identical: R.reduce(identicalReducer, R.__, payload.searchresult.document),
        list: R.compose(R.uniqBy(articleId), R.concat(interceptedDocuments)),
      })(state)
    }

    case Actions.FETCH_MORE_ARTICLES_SUCCESS: {
      const interceptedDocuments = preprocessArticles(payload.response.searchresult.document)

      interceptedDocuments?.forEach((d) => {
        d.matches = reduceDuplicateSnippetOccurrence(d.matches)
      })

      return R.evolve({
        /* eslint-disable-next-line no-underscore-dangle */
        identical: R.reduce(identicalReducer, R.__, payload.response.searchresult.document),
        list: R.compose(
          R.uniqBy(articleId),
          /* eslint-disable-next-line no-underscore-dangle */
          R.concat(R.__, interceptedDocuments),
        ),
      })(state)
    }

    case Actions.CHECK_ARTICLE_TOGGLE:
      return R.evolve(
        {
          checked: R.ifElse(
            R.prop(articleId(payload)),
            R.dissoc(articleId(payload)),
            R.assoc(articleId(payload), true),
          ),
        },
        state,
      )

    case Actions.UNCHECK_ALL_ARTICLES:
      return R.assoc('checked', {}, state)

    case Actions.NEXT_IDENTICAL: {
      const identicalArticles = payload.identical_documents.document

      return R.evolve(
        {
          identical: {
            [articleId(payload)]: R.compose(
              /* eslint-disable-next-line no-underscore-dangle */
              R.modulo(R.__, identicalArticles.length),
              R.inc,
            ),
          },
        },
        state,
      )
    }

    case Actions.PREVIOUS_IDENTICAL: {
      const identicalArticles = payload.identical_documents.document

      return R.evolve(
        {
          identical: {
            [articleId(payload)]: R.compose(
              /* eslint-disable-next-line no-underscore-dangle */
              R.modulo(R.__, identicalArticles.length),
              R.dec,
              R.add(identicalArticles.length),
            ),
          },
        },
        state,
      )
    }

    case Actions.SET_ACTIVE_IDENTICAL: {
      const { article, index } = payload

      return R.evolve(
        {
          identical: {
            [articleId(article)]: R.always(index),
          },
        },
        state,
      )
    }

    case Actions.NEXT_ACTIVE_ARTICLE:
      return R.evolve({
        active: {
          index: R.compose(R.clamp(0, state.list.length), R.inc),
          source: R.always('keyPress'),
        },
      })(state)

    case Actions.PREVIOUS_ACTIVE_ARTICLE:
      return R.evolve({
        active: {
          index: R.compose(R.clamp(0, state.list.length), R.dec),
          source: R.always('keyPress'),
        },
      })(state)

    case Actions.SET_ACTIVE_ARTICLE: {
      const { source, index } = payload

      return R.evolve(
        {
          active: {
            index: R.always(R.clamp(0, state.list.length, index)),
            source: R.always(source),
          },
        },
        state,
      )
    }

    case Actions.TAG_ARTICLES: {
      const { articles, tag, weight = tag?.type === TAG_TYPES.MENTOMETER ? 0 : 1, toTagOnlyMainArticle } = payload || {}
      const articleArray = Array.isArray(articles) ? articles : [articles]

      const synthTag = {
        id: tag?.id,
        is_owner: 1,
        weight,
        set: OpointTimestampToTimestamp(),
      }

      const setJustForMainArticle = (article) => R.assocPath(['tags', tag?.id], synthTag)(article)

      const setTag = R.evolve({
        tags: R.assoc(`${tag?.id}`, synthTag),
        identical_documents: (identicalDocuments: Object) => {
          if (identicalDocuments.count === 0) {
            return identicalDocuments
          }
          const { cnt, document: identicalArticles } = identicalDocuments
          const updatedIdenticals = identicalArticles?.map(setTag)

          return { cnt, document: updatedIdenticals }
        },
      })

      const updateList = (articlesList) => {
        const affectedArticles = getAffectedArticles(
          toTagOnlyMainArticle ? setJustForMainArticle : setTag,
          articleArray,
          articlesList,
        )

        return articlesList.reduce((a, v) => a.concat(affectedArticles[v.id] ? affectedArticles[v.id] : v), [])
      }

      return R.evolve(
        {
          list: updateList,
        },
        state,
      )
    }

    case Actions.UNTAG_ARTICLES: {
      /* eslint-disable-next-line  prefer-const */
      let { articles, tag } = payload

      if (!Array.isArray(articles)) {
        articles = [articles]
      }

      const unsetTag = R.evolve({
        tags: R.dissoc(`${tag.id}`),
        identical_documents: (identicalDocuments: Object) => {
          if (identicalDocuments.count === 0) {
            return identicalDocuments
          }
          const { cnt, document: identicalArticles } = identicalDocuments
          const updatedIdenticals = identicalArticles?.map(unsetTag)

          return { cnt, document: updatedIdenticals }
        },
      })

      const updateList = (articlesList) => {
        const affectedArticles = getAffectedArticles(unsetTag, articles, articlesList)

        // replace the newly tagged articles
        return articlesList.reduce((a, v) => a.concat(affectedArticles[v.id] ? affectedArticles[v.id] : v), [])
      }

      return R.evolve(
        {
          list: updateList,
        },
        state,
      )
    }

    case Actions.TAG_SINGLE_ARTICLE: {
      const { article, tag, originalArticle, weight = tag.type === TAG_TYPES.MENTOMETER ? 0 : 1 } = payload || {}

      const synthTag = {
        id: tag?.id,
        is_owner: 1,
        weight,
        set: OpointTimestampToTimestamp(),
      }

      const updateList = (articlesList) => {
        const affectedArticles = {
          [originalArticle.id]: R.evolve({
            identical_documents: (identicalDocuments: Object) => {
              if (identicalDocuments.count === 0) {
                return identicalDocuments
              }
              const { cnt, document: identicalArticles } = identicalDocuments
              const updatedIdenticals = identicalArticles?.map((identArticle) => {
                if (identArticle.id_article === article.id_article) {
                  return R.evolve({
                    tags: R.assoc(`${tag?.id}`, synthTag),
                  })(identArticle)
                }

                return identArticle
              })

              return { cnt, document: updatedIdenticals }
            },
          })(originalArticle),
        }

        // replace the newly tagged articles
        return articlesList.reduce((a, v) => a.concat(affectedArticles[v.id] ? affectedArticles[v.id] : v), [])
      }

      return R.evolve({
        list: updateList,
      })(state)
    }

    case Actions.UNTAG_SINGLE_ARTICLE: {
      const { article, tag, originalArticle } = payload

      const updateList = (articlesList) => {
        const affectedArticles = {
          [originalArticle.id]: R.evolve({
            identical_documents: (identicalDocuments: Object) => {
              if (identicalDocuments.count === 0) {
                return identicalDocuments
              }
              const { cnt, document: identicalArticles } = identicalDocuments
              const updatedIdenticals = identicalArticles?.map((identArticle) => {
                if (identArticle.id_article === article.id_article) {
                  return R.evolve({
                    tags: R.dissoc(`${tag.id}`),
                  })(identArticle)
                }

                return identArticle
              })

              return { cnt, document: updatedIdenticals }
            },
          })(originalArticle),
        }

        // replace the newly tagged articles
        return articlesList.reduce((a, v) => a.concat(affectedArticles[v.id] ? affectedArticles[v.id] : v), [])
      }

      return R.evolve({
        list: updateList,
      })(state)
    }

    case Actions.DELETE_ARTICLES: {
      /* eslint-disable-next-line  prefer-const */
      let { articles, tag } = payload || {}

      if (!Array.isArray(articles)) {
        articles = [articles]
      }

      const synthTag = {
        id: tag?.id,
        is_owner: 1,
        set: OpointTimestampToTimestamp(),
      }

      const updateList = (articlesList) => {
        const af = getAffectedArticles(
          (article) => R.assocPath(['tags', tag?.id], synthTag)(article),
          articles,
          articlesList,
        )

        // replace the newly tagged articles
        return articlesList.reduce((a, v) => a.concat(af[v.id] ? af[v.id] : v), [])
      }

      return R.evolve(
        {
          list: updateList,
        },
        state,
      )
    }

    case Actions.UNDELETE_ARTICLES: {
      /* eslint-disable-next-line */
      let { articles, tag } = payload

      if (!Array.isArray(articles)) {
        articles = [articles]
      }

      const updateList = (articlesList) => {
        const af = getAffectedArticles(
          (article) => R.assocPath(['tags', tag.id], undefined)(article),
          articles,
          articlesList,
        )

        // replace the newly tagged articles
        return articlesList.reduce((a, v) => a.concat(af[v.id] ? af[v.id] : v), [])
      }

      return R.evolve(
        {
          list: updateList,
        },
        state,
      )
    }

    case Actions.ADD_ARTICLE_INIT: {
      return R.evolve({
        addArticleForm: R.merge({
          tagFilter: '',
          tags: {},
          suggestionsSource: [],
          suggestionsCountry: [],
          searchGeoFilter: {},
          isSaving: false,
        }),
      })(state)
    }

    case Actions.ADD_ARTICLE_MODAL_CLOSE:
      return R.assoc('addArticleForm', {}, state)

    case Actions.ADD_ARTICLE_TOGGLE_TAG: {
      const { tag } = payload

      return R.evolve({
        addArticleForm: {
          tags: R.ifElse(
            R.has(tag.id),
            R.dissoc(tag.id),
            R.assoc(tag.id, Object.assign(tag, { weight: TAG_VALUES[tag.type].DEFAULT })),
          ),
        },
      })(state)
    }

    case Actions.ADD_ARTICLE_SUGGESTIONS_SUCCESS: {
      const { suggestions } = payload

      return R.assocPath(['addArticleForm', 'suggestionsSource'], suggestions, state)
    }

    case Actions.ADD_ARTICLE_SUGGESTIONS_COUNTRY_SUCCESS: {
      const { suggestions } = payload

      let countries = suggestions
      countries = R.filter((i) => COUNTRIES_CODES.indexOf(+i.id) > -1, suggestions)
      countries = simpleSort(countries, 'name')

      return R.assocPath(['addArticleForm', 'suggestionsCountry'], countries, state)
    }

    case Actions.ADD_ARTICLE_SUGGESTIONS_LANG_SUCCESS: {
      const { suggestions } = payload
      const sortedSuggestions = simpleSort(suggestions, 'name')

      return R.assocPath(['addArticleForm', 'suggestionsLang'], sortedSuggestions, state)
    }

    case Actions.ADD_ARTICLE_SEARCHFILTER_TOGGLED:
      return R.ifElse(
        R.compose((x) => x && x.id === payload.id, R.view(R.lensPath(['addArticleForm', 'site', 0]))),
        R.assocPath(['addArticleForm', 'site'], []),
        R.evolve({
          addArticleForm: {
            site: R.always([payload]),
          },
        }),
      )(state)

    case Actions.ADD_ARTICLE_SEARCHFILTER_COUNTRY_TOGGLED:
      return R.ifElse(
        R.compose((x) => x && x.id === payload.id, R.view(R.lensPath(['addArticleForm', 'geo', 0]))),
        R.assocPath(['addArticleForm', 'geo'], []),
        R.evolve({
          addArticleForm: {
            geo: R.always([payload]),
            searchGeoFilter: R.always({ [filterId(payload)]: payload }),
            site: R.always([]),
          },
        }),
      )(state)

    case Actions.ADD_ARTICLE_SEARCHFILTER_LANG_TOGGLED:
      return R.ifElse(
        R.compose((x) => x && x.id === payload.id, R.view(R.lensPath(['addArticleForm', 'language', 0]))),
        R.assocPath(['addArticleForm', 'language'], []),
        R.evolve({
          addArticleForm: {
            language: R.always([payload]),
          },
        }),
      )(state)

    case Actions.ADD_ARTICLE_FILE_UPLOAD_SUCCESS: {
      /**
       * Due to api behaviour which can return data object
       * with `file` field or `files` field.
       * Or can return nothing so we take an original
       * uploaded file and its name
       */
      const {
        data: { file, files },
        original,
      } = payload

      return R.evolve({
        addArticleForm: {
          fileNames: R.append(
            (file && file.filename) ||
              R.path([0, 'filename'], files) || // only 1 file uploading is being supported
              original.get('file').name,
          ),
        },
      })(state)
    }

    case Actions.ADD_ARTICLE:
      return R.assocPath(['addArticleForm', 'isSaving'], true, state)

    case Actions.ADD_ARTICLE_FAILURE:
      return R.assocPath(['addArticleForm', 'isSaving'], false, state)

    case Actions.ADD_ARTICLE_SUCCESS:
      const { id_site, id_article } = payload
      const idForSearch = `id:${id_site}_${id_article}`

      navigator.clipboard.writeText(idForSearch)

      return R.compose(
        R.assocPath(['addArticleForm', 'isSaving'], false),
        R.assocPath(['addArticleForm', 'fileNames'], []),
      )(state)

    case Actions.EDIT_ARTICLE_MODAL_OPEN: {
      const { article, preview = false } = payload

      return R.compose(R.assoc('editedArticlePreviewMode', preview), R.assoc('editedArticle', article))(state)
    }

    case Actions.EDIT_TAGS_MODAL_OPEN:
      return R.assoc('updatedArticle', payload)(state)

    case Actions.UPDATED_ARTICLE: {
      return R.assoc('updatedArticle', payload)(state)
    }

    case Actions.EDIT_ARTICLE: {
      return R.assoc('editedArticleXHRStatus', 'IN_PROGRESS', state)
    }

    case Actions.EDIT_ARTICLE_FAILURE: {
      return R.assoc('editedArticleXHRStatus', 'NOT_INITIATED', state)
    }
    case Actions.EDIT_ARTICLE_SUCCESS: {
      const { article } = payload

      return R.evolve(
        {
          editedArticleXHRStatus: R.always('NOT_INITIATED'),
          editedArticlePreviewMode: R.always(true),
          list: R.map(R.when(eqArticles(article), R.always(article))),
          editedArticle: R.always(article),
        },
        state,
      )
    }
    case Actions.EDIT_ECB_ARTICLE_SUCCESS: {
      const { article } = payload

      return R.evolve(
        {
          list: R.map(R.when(eqArticles(article), R.always(article))),
        },
        state,
      )
    }

    case Actions.SHARE_ARTICLE_MODAL_CLOSE: {
      return R.evolve({
        shareArticleXHRStatus: R.always('NOT_INITIATED'),
        shareArticleMessage: R.always(''),
        shareArticleAttachmentFlag: R.always(false),
        editedArticlePreviewMode: R.always(initialState.shareArticleAttachmentFlag),
      })(state)
    }

    case Actions.SHARE_ARTICLES: {
      return R.assoc('shareArticleXHRStatus', 'IN_PROGRESS', state)
    }

    case Actions.SHARE_ARTICLES_SUCCESS: {
      return R.assoc('shareArticleXHRStatus', 'SUCCESS', state)
    }

    case Actions.SHARE_ARTICLES_FAILURE: {
      return R.assoc('shareArticleXHRStatus', 'FAILURE', state)
    }

    case Actions.SHARE_ARTICLES_TOGGLE_ATTACHMENT_TOGGLE: {
      return R.assoc('shareArticleAttachmentFlag', !state.shareArticleAttachmentFlag, state)
    }

    case Actions.SHARE_ARTICLES_CHANGE_MESSAGE: {
      const { message } = payload

      return R.assoc('shareArticleMessage', message, state)
    }

    case Actions.EDIT_ARTICLE_PREVIEW_MODE_ON: {
      return R.assoc('editedArticlePreviewMode', true, state)
    }

    case Actions.EDIT_ARTICLE_PREVIEW_MODE_OFF: {
      return R.assoc('editedArticlePreviewMode', false, state)
    }

    case Actions.EDIT_ARTICLES_STATE: {
      editedArticlesArr.push(payload)

      return R.assoc('editedArticleState', editedArticlesArr, state)
    }

    default:
      return state
  }
}

export default articlesReducer
