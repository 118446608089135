import R from 'ramda'
import Rx from 'rx-dom'

import type { Article, ArticleTag } from '../../new-components/types/article'
import type { Tag } from '../../new-components/types/tag'
import { LogActions, logArticleAction } from '../../opoint/articles'
import { getAllIdenticalArticles } from '../articles/index'
import config from '../common/config'
import type { TagWeight } from '../flow'

/**
 * Constansts
 */

export const TAG_TYPES = {
  GLOBAL_TRASH: 4,
  KEYWORD: 1,
  MENTOMETER: 2,
  ALERT: 3,
  PROFILE_TRASH: 0,
}
export const TAG_VALUES = {
  // keyword
  1: {
    MAX: 6,
    DEFAULT: 1,
    MIN: 1,
  },
  // mentometer
  2: {
    MAX: 3,
    DEFAULT: 0,
    MIN: -3,
  },
  // alert
  3: {
    MAX: 7,
    DEFAULT: 1,
    MIN: 1,
  },
}
export const TAG_VISIBILITY = {
  WHEN_SET: 1,
  ALWAYS: 2,
}

// If there is exactly one tag, take its id instead of parent id
function getAlertTagId(tag: Tag) {
  return tag.children && tag.children.length === 1 ? tag.children[0].id : tag.id
}

/**
 * Functions
 */
export function getIncrementedWeight(articleTag: ArticleTag, { type }: Tag) {
  const { weight = TAG_VALUES[type].DEFAULT } = articleTag

  return weight < TAG_VALUES[type].MAX ? weight + 1 : TAG_VALUES[type].MIN
}

export function getDecrementedWeight(articleTag: ArticleTag, { type }: Tag) {
  const { weight = TAG_VALUES[type].DEFAULT } = articleTag

  return weight > TAG_VALUES[type].MIN ? weight - 1 : TAG_VALUES[type].MAX
}

export function shouldDisplayTagWeight(articleTags: { [key: string]: ArticleTag }, tagId: number) {
  return !!(articleTags[tagId] && articleTags[tagId].weight)
}

export function isTheWholeGroupUntagged(article: Article, tag: Tag) {
  return !article.tags[tag.id]
}

export function isEveryArticleInGroupUntagged(
  identicalArticles: Array<Article>,
  tagToToggle: Tag,
  untaggingArticle: Article,
) {
  const articles = R.reject(R.propEq('id_article', untaggingArticle.id_article), identicalArticles)
  let is = true
  let i = 0
  while (is && i < articles.length) {
    const identicalArticle = articles[i]
    // @ts-ignore
    if (identicalArticle.tags[tagToToggle.id]) {
      is = false
    }
    i += 1
  }

  return is
}

export function toggleVisibility(tag: Tag): Tag {
  let visibility = null

  switch (tag.visibility) {
    case TAG_VISIBILITY.WHEN_SET:
      visibility = TAG_VISIBILITY.ALWAYS
      break
    case TAG_VISIBILITY.ALWAYS:
      visibility = TAG_VISIBILITY.WHEN_SET
      break
    default:
      break
  }

  return { ...tag, visibility }
}

export function getTaggedIdenticalArticlesCount(
  originalArticleTags: { [key: string]: ArticleTag },
  tag: Tag,
  identicalDocuments?: {
    cnt: number
    document: Array<Article>
  },
) {
  if (identicalDocuments && identicalDocuments.cnt !== 0) {
    const tagId = `${tag.id}`
    let countIdent = 0

    if (originalArticleTags[tagId]) {
      countIdent = identicalDocuments.document.reduce((summ, identArticle) => {
        if (identArticle.tags[tagId]) {
          return summ + 1
        }

        return summ
      }, 0)
    }

    return (
      !!countIdent &&
      countIdent !== identicalDocuments.document.length &&
      `${countIdent}/${identicalDocuments.document.length}`
    )
  }

  return null
}

export async function lastSort(tagId: number) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${tagId}/last-sorted/`),
    method: 'GET',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function customSort(tagId: number, articles: Array<{ id_article: string; id_site: string }>) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${tagId}/sort/custom/`),
    method: 'POST',
    body: JSON.stringify({
      articles,
    }),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function sortTags(
  tagId: number,
  {
    fromTimestamp,
    toTimestamp,
    sortOrder,
    sortType,
    // @ts-ignore
    stimestampUsed,
  }: {
    fromTimestamp?: number
    toTimestamp?: number
    stimestamp?: Array<number>
    sortOrder: 'asc' | 'desc'
    sortType: 'date' | 'priority'
  },
) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${tagId}/sort/`),
    method: 'POST',
    responseType: 'text',
    body: JSON.stringify({
      fromTimestamp,
      toTimestamp,
      sortOrder,
      sortType,
      stimestampUsed,
    }),
  })

  return Rx.DOM.ajax(requestHeaders).toPromise()
}

export async function getTags() {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/tags/'),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function tagArticles(articles: Array<Article>, tag: Tag, weight: TagWeight): Promise<Tag> {
  const { type } = tag
  const id = type === TAG_TYPES.ALERT ? getAlertTagId(tag) : tag.id
  const allArticles = R.flatten(R.map(getAllIdenticalArticles, articles))
  const articlesIdsFn = ({ id_site, id_article, internal_search_reply, stimestamp }) => ({
    id_site,
    id_article,
    stimestamp,
    matchinfo: internal_search_reply.text,
  })

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${id}/articles/tag/`),
    method: 'POST',
    body: JSON.stringify({
      articles: allArticles?.map(articlesIdsFn),
      weight: weight || TAG_VALUES[type].DEFAULT,
    }),
  })

  allArticles?.forEach((article) => {
    logArticleAction({
      id_article: article.id_article,
      id_site: article.id_site,
      action: [LogActions.ArticleAddedToTag],
    })
  })

  return (
    Rx.DOM.ajax(requestHeaders)
      .toPromise()
      .then(({ response, response: { status } }) => ({ articles, response, status }))
      // @ts-ignore
      .catch(({ status }) => Promise.reject(new Error({ articles, status })))
  )
}

export async function untagArticles(articles: Array<Article>, tag: Tag, weight: TagWeight = 1): Promise<Tag> {
  const id = tag.type === TAG_TYPES.ALERT ? getAlertTagId(tag) : tag.id
  const allArticles = R.flatten(R.map(getAllIdenticalArticles, articles))

  const articlesIdsFn = ({ id_site, id_article, stimestamp }) => ({ id_site, id_article, stimestamp })

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${id}/articles/untag/`),
    method: 'POST',
    body: JSON.stringify({
      articles: allArticles?.map(articlesIdsFn),
      weight,
    }),
  })

  return (
    Rx.DOM.ajax(requestHeaders)
      .toPromise()
      .then(({ response, response: { status } }) => ({ articles, response, status }))
      // @ts-ignore
      .catch(({ status }) => Promise.reject(new Error({ articles, status })))
  )
}

export async function untagSingleArticle(article: Article, tag: Tag, weight: TagWeight = 1): Promise<Tag> {
  const id = tag.type === TAG_TYPES.ALERT ? getAlertTagId(tag) : tag.id

  /* eslint-disable-next-line */
  const { id_site, id_article, stimestamp } = article

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${id}/articles/untag/`),
    method: 'POST',
    body: JSON.stringify({
      articles: [{ id_site, id_article, stimestamp }],
      weight,
    }),
  })

  return (
    Rx.DOM.ajax(requestHeaders)
      .toPromise()
      .then(({ response, response: { status } }) => ({ articles: [article], response, status }))
      // @ts-ignore
      .catch(({ status }) => Promise.reject(new Error({ articles: [article], status })))
  )
}

export async function tagSingleArticle(article: Article, tag: Tag, weight: TagWeight): Promise<Tag> {
  const { type } = tag
  const id = type === TAG_TYPES.ALERT ? getAlertTagId(tag) : tag.id

  /* eslint-disable-next-line */
  const { id_site, id_article, internal_search_reply, stimestamp } = article

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${id}/articles/tag/`),
    method: 'POST',
    body: JSON.stringify({
      articles: [
        {
          id_site,
          id_article,
          matchinfo: internal_search_reply.text,
          stimestamp,
        },
      ],
      weight: weight || TAG_VALUES[type].DEFAULT,
    }),
  })

  logArticleAction({ id_article, id_site, action: [LogActions.ArticleAddedToTag] })

  return (
    Rx.DOM.ajax(requestHeaders)
      .toPromise()
      .then(({ response, response: { status } }) => ({ articles: [article], response, status }))
      // @ts-ignore
      .catch(({ status }) => Promise.reject(new Error({ articles: [article], status })))
  )
}

export function toggleTagVisibility(tag: Tag): Tag {
  return R.over(R.lensProp('visibility'), (v) => (v === 2 ? 1 : 2), tag)
}

export function isTagOnArticle(tag: Tag, a: Article): boolean {
  /* eslint-disable-next-line no-underscore-dangle */
  const articleTags = R.map(R.curry(parseInt)(R.__, 10), Object.keys(a.tags))
  const children = tag.children ? R.pluck('id', tag.children) : []
  const ids = [tag.id, ...children]

  // @ts-ignore
  return ids.reduce((prev, curr) => articleTags.includes(curr) || prev, false)
}

export async function addTag(tag: Tag): Promise<void> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/tags/'),
    method: 'POST',
    body: JSON.stringify(tag),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function editTag(tag: Tag): Promise<void> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${tag.id}/`),
    method: 'PUT',
    body: JSON.stringify(tag),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function deleteTag(tagId: string): Promise<void> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${tagId}/`),
    method: 'DELETE',
    responseType: 'text',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function updateTagsOrder(position: number, tagId: number): Promise<void> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/tags/sort/'),
    method: 'POST',
    responseType: 'text',
    body: JSON.stringify({ tagId, position }),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
