// @ts-nocheck

import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import type { Template, Action } from '../opoint/flow'

type TemplatesState = {
  list: Array<Template>
  previewModalIsOpen: boolean
  sortableModules: any
}

export const initialState = {
  list: [],
  previewModalIsOpen: false,
  lastFetchedTemplate: null,
  templateDetails: {},
  activeSortableModule: '',
  sortableModules: {},
}

const templatesReducer = (state: TemplatesState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.TEMPLATES_FETCH_SUCCESS:
      return R.assoc('list', payload, state)

    case Actions.TEMPLATE_PREVIEW_FETCH_SUCCESS:
      return R.assoc('lastFetchedTemplate', payload, state)

    case Actions.TEMPLATE_PREVIEW_MODAL_CLOSE:
      return R.assoc('previewModalIsOpen', false, state)

    case Actions.TEMPLATE_PREVIEW_MODAL_OPEN:
      return R.evolve({
        lastFetchedTemplate: R.always(null),
        previewModalIsOpen: R.always(true),
      })(state)

    case Actions.FETCH_TEMPLATE_DETAIL_SUCCESS: {
      const { id, maxLevel, modules } = payload

      const sortableModules = {}
      R?.forEachObjIndexed((module, moduleName) => {
        if (module.sortable) {
          let moduleSettings = module.widgets
          if (R.all(R.propEq('sortIndex', 0), moduleSettings)) {
            moduleSettings = moduleSettings?.map((setting, index) => R.assoc('sortIndex', index, setting))
          }
          sortableModules[moduleName] = moduleSettings
        }
      })(modules)

      return R.compose(
        R.evolve({ sortableModules: R.always(sortableModules) }),
        R.assocPath(['templateDetails', id], R.assoc('maxLevel', maxLevel, modules)),
      )(state)
    }

    case Actions.UPDATE_ACTIVE_TEMPLATE: {
      const { activeTemplate } = payload

      return R.assoc('activeTemplate', activeTemplate, state)
    }

    case Actions.TEMPLATE_EDITOR_MODULE_SORT_MODAL_OPEN: {
      const { module } = payload

      return R.assoc('activeSortableModule', module, state)
    }

    case Actions.TEMPLATE_EDITOR_MODULE_SORT_MODAL_CLOSE:
      return R.assoc('activeSortableModule', '', state)

    case Actions.TEMPLATE_EDITOR_FORM_MODAL_CLOSE:
      return R.assoc('sortableModules', {}, state)

    case Actions.TEMPLATE_EDITOR_MODULE_SORT_MOVE_SETTING: {
      const { moduleName, widgets } = payload
      const widgetWithRightIndexes = widgets?.map((setting, index) => R.assoc('sortIndex', index, setting))

      return R.evolve({
        sortableModules: { [moduleName]: R.always(widgetWithRightIndexes) },
      })(state)
    }

    default:
      return state
  }
}

export default templatesReducer
